import React, { PureComponent } from 'react'

import { Box, Button } from '@gousto-internal/citrus-react'

import { Spotlight } from 'routes/Menu/components/Spotlight'
import { Tooltip } from 'routes/Menu/components/Tutorial/Tooltip'
import {
  isElementHidden,
  getSpotlightLocation,
  getTooltipProperties,
} from 'routes/Menu/components/Tutorial/helpers'
import { onEnter } from 'utils/accessibility'

import css from './Step.css'

export type StepProps = {
  children: React.ReactNode
  last?: boolean
  next?: () => void
  onClose?: () => void
  selector: string
}

type StepState = {
  x: number
  y: number
  style: React.CSSProperties
  arrow: string
}

export class Step extends PureComponent<StepProps, StepState> {
  constructor(props: StepProps) {
    super(props)
    this.state = {
      x: 0,
      y: 0,
      style: {},
      arrow: '',
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.recalculateLocations)
    this.recalculateLocations()
  }

  componentDidUpdate(_: StepProps, prevState: StepState) {
    if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
      this.recalculateLocations()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recalculateLocations)
  }

  recalculateLocations = () => {
    const { selector, next } = this.props
    const fromTabletToMobile = window.innerWidth < 756 ? 250 : 300
    const stepWidth = window.innerWidth < 456 ? 200 : fromTabletToMobile
    if (isElementHidden(selector)) {
      next?.()

      return
    }

    const { x, y } = getSpotlightLocation(selector)
    const { style, arrow } = getTooltipProperties(selector, stepWidth)

    this.setState({
      x,
      y,
      style,
      arrow,
    })
  }

  render() {
    const { x, y, style, arrow } = this.state
    const { children, last, onClose, next } = this.props

    return (
      <div>
        <Spotlight x={x} y={y} onClick={onClose} />
        <div className={css.tooltip} style={style}>
          <Tooltip arrow={arrow} onClose={onClose}>
            <Box paddingBottom={5}>{children}</Box>
            <Button
              data-testing="tutorialStepCta"
              onClick={next}
              onKeyDown={onEnter(next)}
              tabIndex={0}
              width="100%"
            >
              {last ? 'Ok' : 'Next'}
            </Button>
          </Tooltip>
        </div>
      </div>
    )
  }
}
