import { ThunkExperiments } from '@library/experimentation'

import { actionTypes } from 'actions/actionTypes'
import { promoChange, promoToggleModalVisibility } from 'actions/promos'
import { redirect } from 'actions/redirect'
import { clickGetStarted } from 'actions/trackingKeys'
import { isOptimizelyFeatureEnabledFactory } from 'containers/OptimizelyRollouts'
import { GIFTING_EXPERIMENT_PROMO_CODE_DATA, PROMO_CODE_DATA } from 'routes/Home/homeConfig'
import {
  DISCOUNT_SOFTENING_FREE_DELIVERY_BANNER_DATA,
  isValidDiscountSofteningFreeDeliveryVariation,
} from 'routes/Home/homeHooks'
import { getPromoBannerState } from 'routes/Home/homeUtils'
import { ThunkActionCreator } from 'routes/Home/types/ThunkActionCreator'
import { getIsAuthenticated } from 'selectors/auth'
import { getUTMAndPromoCode } from 'selectors/tracking'
import logger from 'utils/logger'

import {
  DiscountSofteningFreeDeliveryTreatments,
  hasConsentForAnalytics,
  overrideVariationKey,
} from '../hooks/useGetDiscountSofteningFreeDelivery'

const getIsTestAllocationFactoryEnabled = isOptimizelyFeatureEnabledFactory(
  'beetroots_test_allocation_factory_web',
)

export const applyPromoCodeAndShowModal: ThunkActionCreator =
  () =>
  async (dispatch, getState, { experiments }: ThunkExperiments) => {
    const state = getState()
    const isAuthenticated = getIsAuthenticated(state)
    const hasAcceptedCookies = hasConsentForAnalytics()
    const shouldBeAllocatedToExperiment = !isAuthenticated && hasAcceptedCookies
    const { canApplyPromo } = getPromoBannerState(state)
    if (!canApplyPromo) {
      return
    }

    const getVariationKey = (experimentName: string) => {
      const decision = experiments.decide(experimentName)

      return decision?.variationKey ?? 'c'
    }

    // [BEET-417] Override the variation key for the free delivery aspect of this experiment as per analytics request
    const discountSofteningFreeDeliveryVariationKey = shouldBeAllocatedToExperiment
      ? overrideVariationKey(getVariationKey('beetroots_ofx_discount_softening_free_delivery_v2'))
      : 'c'

    const giftingVariationKey = getVariationKey('beetroots_ofx_discount_gift')

    const isDiscountSofteningExperimentEnabled =
      discountSofteningFreeDeliveryVariationKey &&
      isValidDiscountSofteningFreeDeliveryVariation(discountSofteningFreeDeliveryVariationKey)

    const isDiscountGiftingExperimentEnabled = giftingVariationKey === 't1' && !isAuthenticated

    const getPromoCode = () => {
      if (isDiscountSofteningExperimentEnabled) {
        const allocatedPromoCode =
          DISCOUNT_SOFTENING_FREE_DELIVERY_BANNER_DATA[
            discountSofteningFreeDeliveryVariationKey as DiscountSofteningFreeDeliveryTreatments
          ].defaultPromoCode

        return allocatedPromoCode
      }
      if (isDiscountGiftingExperimentEnabled) {
        return GIFTING_EXPERIMENT_PROMO_CODE_DATA.defaultPromoCode
      }

      return PROMO_CODE_DATA.defaultPromoCode
    }

    const promoCode = getPromoCode()

    try {
      await dispatch(promoChange(promoCode))
    } catch (err) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.warning(`error fetching promo code ${promoCode} - ${(err as Error).message}`, err)

      return
    }
    dispatch(promoToggleModalVisibility(true))
  }

export const trackGetStarted: ThunkActionCreator =
  (trackingSectionName: string) =>
  (dispatch, getState, { experiments }) => {
    const { promoCode, UTM } = getUTMAndPromoCode(getState())
    experiments.track('start_onboarding')
    dispatch({
      type: actionTypes.TRACKING,
      trackingData: {
        actionType: clickGetStarted,
        section: trackingSectionName,
        ...UTM,
        promoCode,
      },
    })
  }

export const homeGetStarted: ThunkActionCreator =
  (ctaUri: string, sectionForTracking?: string) => async (dispatch, getState) => {
    if (sectionForTracking) {
      dispatch(trackGetStarted(sectionForTracking))
    }

    await dispatch(applyPromoCodeAndShowModal())

    dispatch(redirect(ctaUri))

    const isTestAllocationFactoryEnabled = await getIsTestAllocationFactoryEnabled(
      dispatch,
      getState,
    )
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    logger.info(`beetroots_test_allocation_factory_web=${isTestAllocationFactoryEnabled}`)
  }
