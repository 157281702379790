import { connect } from 'react-redux'
import { trackClickRateRecipes } from '../../../Ratings/actions/feedback'
import { trackAccountNavBarClick } from '../../actions/navBar'
import NavBar from './NavBar'

const mapDispatchToProps = {
  trackClickRateRecipes,
  trackAccountNavBarClick,
}

const NavBarContainer = connect(null, mapDispatchToProps)(NavBar)

export { NavBarContainer }
