import React from 'react'

import { Alert } from 'goustouicomponents'
import PropTypes from 'prop-types'

import { Button, ButtonColorVariant } from '@gousto-internal/citrus-react'

class OrderCancelButton extends React.PureComponent {
  handleCancelBox = () => {
    const { orderState, orderId, deliveryDayId, orderCancelStart, deliveryDay } = this.props
    if (orderState !== 'scheduled') {
      orderCancelStart(orderId, deliveryDayId, deliveryDay, 'pending')
    } else {
      orderCancelStart(null, deliveryDayId, deliveryDay, 'projected')
    }
  }

  render() {
    const {
      contentPending,
      didCancelProjectedError,
      deliveryDayId,
      orderId,
      osrDeliveryDayId,
      osrOrderId,
    } = this.props

    let pending = false
    if (contentPending && deliveryDayId === osrDeliveryDayId) pending = true
    if (contentPending && orderId === osrOrderId) pending = true

    return (
      <div>
        {didCancelProjectedError ? (
          <Alert type="danger">
            Whoops, there was a problem cancelling this order, please try again.
          </Alert>
        ) : null}
        <Button
          data-testid="cancelButton"
          colorVariant={ButtonColorVariant.Secondary}
          onClick={this.handleCancelBox}
          width={['100%', 'auto']}
          disabled={pending}
        >
          Skip delivery
        </Button>
      </div>
    )
  }
}

OrderCancelButton.propTypes = {
  orderId: PropTypes.string,
  deliveryDayId: PropTypes.string,
  osrOrderId: PropTypes.string,
  osrDeliveryDayId: PropTypes.string,
  orderState: PropTypes.string,
  didCancelProjectedError: PropTypes.bool,
  orderCancelStart: PropTypes.func,
  deliveryDay: PropTypes.string,
  contentPending: PropTypes.bool,
}

OrderCancelButton.defaultProps = {
  orderId: '',
  deliveryDayId: '',
  osrOrderId: '',
  osrDeliveryDayId: '',
  orderState: '',
  didCancelProjectedError: false,
  orderCancelStart: () => {},
  deliveryDay: '',
  contentPending: false,
}

export default OrderCancelButton
