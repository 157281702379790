import { Box, Space, BorderStyle, Color } from '@gousto-internal/citrus-react'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { OrderExpanded } from './OrderExpanded'
import { datadogLogs } from '@datadog/browser-logs'
import { Notification } from './Notification'

type Props = {
  orderId: string
}

export const OrderExpandedWrapper = ({ orderId }: Props) => {
  const renderOrderExpandedError = ({ error }: { error: Error }) => {
    datadogLogs.logger.error(`Failed when getting the details for order id: ${orderId}. ${error}`)

    return (
      <Box>
        <Space size={8} direction="vertical" />
        <Box
          height="1px"
          width="100%"
          borderStyle={BorderStyle.Solid}
          borderBottomWidth={0.5}
          borderColor={Color.ColdGrey_100}
        />
        <Box>
          <Space size={6} direction="vertical" />
          <Notification
            state="error"
            message="An error occurred when getting the details for this order. Please try again later."
          />
        </Box>
      </Box>
    )
  }

  return (
    <ErrorBoundary fallbackRender={renderOrderExpandedError}>
      <Box>
        <Space size={8} direction="vertical" />
        <Box
          height="1px"
          width="100%"
          borderStyle={BorderStyle.Solid}
          borderBottomWidth={0.5}
          borderColor={Color.ColdGrey_100}
        />
        <Box>
          <Space size={6} direction="vertical" />
          <OrderExpanded />
        </Box>
      </Box>
    </ErrorBoundary>
  )
}
