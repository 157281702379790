export const getHeroDetails = () => ({
  header: 'Gousto Box Prices - How much is a food box?',
  /* eslint-disable-next-line global-require */
  image: require('media/images/box-prices/box-prices-hero.jpg'),
})

export const cta = 'Build your box'

export const boxSizes: Record<string, string[]> = {
  1: ['2', '3', '4', '5'],
  2: ['2', '3', '4', '5'],
  3: ['2', '3', '4', '5'],
  4: ['2', '3', '4', '5'],
  5: ['2', '3'],
}

export const contentBlocks = [
  {
    titles: ['What size boxes does Gousto have?'],
    texts: [
      'Did you know that we’re the first recipe box in Britain to serve 1-5 people? Our food boxes are made to suit a number of households as we understand that every home looks different. Whether you’re a busy family of 5, a couple looking to shake up their dinners, or you live alone and want to cut down on food waste, there’s a box for you.',
      'Don’t forget: you can also change the size of your box and your number of recipes every week, should you ever need.',
    ],
  },
  {
    titles: ['When will I get my first food box delivery?'],
    texts: [
      'It’s easy to sign up to Gousto, in just a few clicks. Choose your box size, decide how many delicious dinners you’d like, and pick the ideal day for your meal delivery. Our boxes are delivered any day of the week. It’s really as simple as that. You can skip a box, pause your subscription and cancel at any time, at no cost.',
      'Why not join over a million of our customers who are already loving Gousto?',
    ],
  },
]

export const quotes = [
  {
    text: 'Exceptionally high quality ingredients, superb meat, great big portions. No waste - one of the best bits - and a significant saving on our weekly food bill. Super excited when the box arrives each week!',
    source: 'Rachel, Leeds',
  },
  {
    text: 'Our boring meals are once again adventurous! Food bill is reduced as I no longer buy veg and meat that I forget to use. On to our third week of orders now - delicious!',
    source: 'Alison, St Albans',
  },
]

export const seo = {
  title: 'Gousto Food Box Prices | Weekly Meal Delivery',
  meta: [
    {
      name: 'description',
      content:
        'Want to know the cost of a Gousto recipe box? Choose a box size between 1 - 5 people, then select your number of recipes to find your box price.',
    },
    {
      name: 'keywords',
      content: 'Gousto, recipe delivery, price, fresh, healthy food, cooking, recipe box',
    },
  ],
}

export const freeDeliveryPill = {
  text: 'Free delivery on your first box!',
}

export const discountInfographicCta = {
  text: 'Build my box with 50% off',
}

export const discountInformationCopy1 = {
  title: 'How does the Gousto discount work?',
  text: 'With our generous new customer discount offer, you can get 50% off your first Gousto box, 40% off your second box and 20% off the next six boxes. That means you can save across your first 8 recipe boxes. Use our box prices selector above to see how much you can save.',
}

export const discountInformationCopy2 = {
  title: 'How do I add the discount? ',
  text1:
    'Click to build your box and our new customer discount is automatically applied for you. Each month we have over 500 recipes to choose from on our menu. Find ',
  speedyMealsLinkText: ' speedy 10 min meals,',
  text2: ' browse our ',
  healthyMealsLinkText: 'health hub',
  text3: ' range and discover our customers’ ',
  everydayFavouritesLinkText: 'everyday favourites.',
}
