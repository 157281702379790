import React from 'react'
import { EmblaOptionsType } from 'embla-carousel'

import {
  AlignItems,
  Box,
  Display,
  DisplayPosition,
  JustifyContent,
  Overflow,
  Space,
} from '@gousto-internal/citrus-react'

import { BANNER_CAROUSEL_IMAGES } from '../../config'
import { Carousel } from './Carousel'
import { BannerImage } from '../../types/types'
import { ResponsiveImage } from './ResponsiveImage'

const carouselOptions: EmblaOptionsType = {
  loop: true,
  startIndex: 3,
}

function Slide({ image }: { image: BannerImage }) {
  const { alt, mobileUrl, desktopUrl } = image
  return (
    <Box
      alignItems={AlignItems.Center}
      display={Display.Flex}
      justifyContent={JustifyContent.Center}
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
      height={['140px', null, '140px']}
      width={['144px', null, '232px']}
      position={DisplayPosition.Relative}
    >
      <ResponsiveImage alt={alt} mobileSrc={mobileUrl} desktopSrc={desktopUrl} />
      <Space size={[1, null, 2]} direction="horizontal" />
    </Box>
  )
}

export function Banner() {
  return (
    <Box data-testid="banner">
      <Carousel ariaLabel="Banner" options={carouselOptions}>
        {BANNER_CAROUSEL_IMAGES.map((image) => (
          <Slide key={image.name.replace(/(\s|,)/gm, '-')} image={image} />
        ))}
      </Carousel>
    </Box>
  )
}
