import React, { useEffect, useState } from 'react'

import { Header } from 'Header'
import { useSelector } from 'react-redux'

import { client } from 'config/routes'
import { useDoubleDeckerNav } from 'hooks/useDoubleDeckerNav'
import { useLocation } from 'routes/Menu/domains/collections/internal/useLocation'
import { getHasProspectNotSeenWizard } from 'routes/Menu/selectors/menu'
import { getIsAuthenticated } from 'selectors/auth'
import { getIsInWizardFunnel } from 'selectors/signup'
import { isWindowDefined } from 'utils/window'

const MOBILE_BREAKPOINT = 767

export const MenuHeader = () => {
  const isAuthenticated = useSelector(getIsAuthenticated)
  const isInWizardFunnel = useSelector(getIsInWizardFunnel)
  const hasProspectNotSeenWizard = useSelector(getHasProspectNotSeenWizard)
  const { pathname } = useLocation()
  const [isMobile, setIsMobile] = useState(false)
  const isMenuPath = pathname === client.menu
  const simpleHeaderForMenu = (isInWizardFunnel && !isAuthenticated) || hasProspectNotSeenWizard
  const simpleHeader = isInWizardFunnel && !isAuthenticated
  const isSimpleHeader = isMenuPath && !isMobile ? simpleHeaderForMenu : simpleHeader
  const doubleDeckerExperimentEnabled = useDoubleDeckerNav()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isWindowDefined() ? window.innerWidth < MOBILE_BREAKPOINT : false)
    }
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Header
      simple={isSimpleHeader}
      doubleDeckerExperimentEnabled={doubleDeckerExperimentEnabled}
      hasLoginModal
    />
  )
}
