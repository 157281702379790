import { queueTrackingEvent } from '@library/tracking-integration'

export enum TrackingActions {
  CLICK_GET_STARTED = 'click_get_started',
}

export enum Sections {
  HERO = 'carousel',
}

type CTAClickTrackingData = {
  action: TrackingActions
  properties: {
    section: Sections
  }
}

export function trackCTAClick(action: TrackingActions, homepageSection: Sections) {
  queueTrackingEvent<CTAClickTrackingData>({
    action,
    properties: {
      section: homepageSection,
    },
  })
}
