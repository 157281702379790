import React from 'react'

import { Box, Display, DisplayPosition, Icon, IconVariant } from '@gousto-internal/citrus-react'

import { onEnter } from 'utils/accessibility'

import { Arrow } from './Arrow'

import css from './Tooltip.css'

export type TooltipProps = {
  arrow?: string
  children?: React.ReactNode
  onClose?: () => void
  style?: React.CSSProperties
  arrowStyle?: React.CSSProperties
  contentStyle?: React.CSSProperties
}

const Tooltip = ({
  arrow = 'top',
  children,
  style,
  arrowStyle,
  contentStyle,
  onClose,
}: TooltipProps) => (
  <div className={css.container} style={style}>
    <Arrow positionY="top" position={arrow} />
    {onClose ? (
      <Box
        data-testid="close-button"
        display={Display.Flex}
        role="button"
        tabIndex={0}
        onClick={onClose}
        onKeyDown={onEnter(onClose)}
        paddingH={1}
        paddingV={1}
        position={DisplayPosition.Absolute}
        right="4px"
        top="34px"
      >
        <Icon name="close" size={5} variant={IconVariant.Actionable}>
          Close tooltip
        </Icon>
      </Box>
    ) : null}
    <div className={css.tooltip__content} style={contentStyle}>
      {children}
    </div>
    <Arrow positionY="bottom" position={arrow} style={arrowStyle} />
  </div>
)

export { Tooltip }
