import { actionTypes } from 'actions/actionTypes'
import {createSelector} from 'reselect'
import {getIsAuthenticated} from 'selectors/auth'
import {getBasketOrderId} from 'selectors/basket'
import {getUserOrders} from 'selectors/user'

function getError(state, action, orderId) {
  const error = state.error.get(action, null)
  const result = error && error.orderId === orderId ? error.error : undefined

  return result
}

const getCancelledOrderError = (state, orderId) => {
  const projectedOrderError = getError(state, actionTypes.PROJECTED_ORDER_CANCEL, orderId)
  const cancelledOrderError = getError(state, actionTypes.CANCELLED_ORDER_RESTORED, orderId)

  return projectedOrderError || cancelledOrderError
}

const isCancelledOrderPending = (state) => {
  const isProjectedOrderCancelPending = state.pending.get(actionTypes.PROJECTED_ORDER_CANCEL, false)
  const isProjectedOrderRestorePending = state.pending.get(actionTypes.PROJECTED_ORDER_RESTORE, false)
  const isCancelledOrderRestoredPending = state.pending.get(actionTypes.CANCELLED_ORDER_RESTORED, false)

  return isProjectedOrderCancelPending || isCancelledOrderRestoredPending || isProjectedOrderRestorePending
}

export const getOrderType = createSelector(
  [getIsAuthenticated, getBasketOrderId, getUserOrders],
  (isAuthenticated, orderId, orders) => {
    if (!isAuthenticated) {
      return 'signup'
    } else if (isAuthenticated && !orderId) {
      return 'transactional'
    } else if (isAuthenticated && orderId) {
      // TODO: in the third NDD iteration we will use orderType in orders directly
      return orders.getIn([orderId, 'subscriptionId']) === null ? 'transactional' : 'subscription'
    }

    return undefined
  }
)

export { getCancelledOrderError, isCancelledOrderPending }
