import React from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'
import classnames from 'classnames'

import { useAuth } from 'routes/Menu/domains/auth'
import { useShouldShowDeliveryDetailsPage } from 'routes/Menu/hooks/useShouldShowDeliveryDetailsPage'

import { MenuDateRange } from '../MenuDateRange'
import { MenuDeliveryDate } from '../MenuDateRange/MenuDeliveryDate'
import { MenuPreferences } from '../MenuPreferences'
import { SearchButton } from '../Search/SearchButton/SearchButton'
import { BackButton } from './BackButton'

import css from './SubHeader.css'

type SubHeaderProps = {
  fromJoin?: boolean
}

export const SubHeader = ({ fromJoin = false }: SubHeaderProps) => {
  const { isAuthenticated } = useAuth()
  const shouldShowDeliveryDetailsPage = useShouldShowDeliveryDetailsPage()

  return (
    <div className={classnames(css[fromJoin ? 'subHeaderSimple' : 'subHeader'])}>
      <div className={css.subHeaderContent}>
        <div className={css.filter}>
          <Box alignItems={AlignItems.Center} display={Display.Flex}>
            {shouldShowDeliveryDetailsPage ? (
              <Box
                display={Display.Flex}
                alignItems={AlignItems.Center}
                flexShrink={0}
                justifyContent={JustifyContent.Center}
                data-testid="deliveryDetailsBackButton-desktop"
              >
                <BackButton />
                <Space size={4} direction="horizontal" />
                <MenuDeliveryDate variant="desktop" />
              </Box>
            ) : (
              <Box flexShrink={0}>
                <MenuDateRange variant="desktop" />
              </Box>
            )}
          </Box>
          <Box paddingH={8} width="50%">
            <SearchButton />
          </Box>
          <div className={css.filterRight}>
            <div className={css.filterSection}>
              {isAuthenticated && <MenuPreferences variant="desktop" />}
            </div>
          </div>
        </div>
      </div>
      <div className={css.mobile}>
        <Box
          alignItems={AlignItems.Center}
          bg={Color.White}
          display={Display.Flex}
          justifyContent={JustifyContent.SpaceBetween}
          paddingH={3}
          paddingV={2}
        >
          {shouldShowDeliveryDetailsPage && (
            <Box
              display={Display.Flex}
              alignItems={AlignItems.Center}
              justifyContent={JustifyContent.Center}
              data-testid="deliveryDetailsBackButton-mobile"
            >
              <BackButton />
              <Space size={4} direction="horizontal" />
            </Box>
          )}
          <Box width="100%">
            <SearchButton />
          </Box>
          {isAuthenticated && <MenuPreferences variant="mobile" />}
        </Box>
      </div>
    </div>
  )
}
