import { fetchCurrentUser, RecipeItem } from '@library/api-user'
import { RecipeAvailabilityMap, useRecipeAvailability } from './useRecipeAvailability'
import useSWR from 'swr'
import { Day, fetchDaysForUser } from '@library/api-deliveries'
import { ApiKeys, LOCALE } from '../configs'
import { useState } from 'react'
import { datadogLogs } from '@datadog/browser-logs'

const formatTimestampDate = (dateString: string): string => {
  const date = new Date(dateString)

  return date.toLocaleDateString(LOCALE, { day: 'numeric', month: 'long', weekday: 'long' })
}

export type DeliveryLeadTime = {
  startTime: string
  endTime: string
  deliveryPrice: string
  coreSlotId: string
  defaultDay: number
  isSlotDefault: boolean
  date: string
}

const getLoadingFlag = (data: unknown, error: unknown): boolean => {
  return !data && !error
}

const getIsRecipeUnavailable = (
  recipeAvailabilityMap: RecipeAvailabilityMap | undefined,
  day: Day,
  recipeItems: RecipeItem[],
  orderNumPortion: string,
) => {
  if (!recipeAvailabilityMap) {
    return false
  }

  const numberOfPortions = parseInt(orderNumPortion)

  return day.daySlotLeadTimes.some((leadTime) => {
    return recipeItems.some((recipe) => {
      if (
        !recipeAvailabilityMap[leadTime.date] ||
        !recipeAvailabilityMap[leadTime.date][recipe.recipeUuid] ||
        !recipeAvailabilityMap[leadTime.date][recipe.recipeUuid][numberOfPortions]
      ) {
        return true
      }

      return recipeAvailabilityMap[leadTime.date][recipe.recipeUuid][numberOfPortions] === false
    })
  })
}

export const useDays = (
  recipeItems: RecipeItem[],
  orderNumPortion: string,
  periodWhenStart: string,
  periodWhenCutoff: string,
  daySlotLeadTimeId: string,
  deliveryDate: string,
) => {
  const [selectedDaySlotLeadTimeId, setSelectedDaySlotLeadTimeId] =
    useState<string>(daySlotLeadTimeId)
  const [selectedDeliveryDate, setDeliveryDate] = useState<string>(deliveryDate)

  const { data: userData, error: userError } = useSWR(ApiKeys.UserCurrent, fetchCurrentUser)

  const { data: days, error: daysError } = useSWR(
    userData
      ? {
          filters: {
            cutoff_datetime_from: periodWhenStart,
            cutoff_datetime_until: periodWhenCutoff,
          },
          sort: 'date',
          direction: 'asc',
          postcode: userData?.user.shippingAddress.postcode,
        }
      : null,
    fetchDaysForUser,
  )

  const deliveryDateStart = days?.[0]?.date || ''
  const deliveryDateEnd = days?.[days?.length - 1]?.date || ''
  const { recipeAvailabilityMap, isLoading: isRecipeUnavailableLoading } = useRecipeAvailability(
    deliveryDateStart,
    deliveryDateEnd,
  )

  const initialState = {
    availableSlots: [],
    isLoading: false,
    selectedSlot: undefined,
    setSelectedDaySlotLeadTimeId,
    setDeliveryDate,
  }

  if (userError) {
    datadogLogs.logger.error(`Error fetching user data ${JSON.stringify(userError)}`)

    return initialState
  }

  if (daysError) {
    datadogLogs.logger.error(
      `Error fetching days data for ${userData?.user.id} ${JSON.stringify(daysError)}`,
    )

    return initialState
  }

  const isLoading =
    getLoadingFlag(userData, userError) ||
    getLoadingFlag(days, daysError) ||
    isRecipeUnavailableLoading

  const availableSlots = days
    ?.map((day) => ({
      id: day.id,
      coreDayId: day.coreDayId,
      date: day.date,
      humanizedDate: formatTimestampDate(day.date),
      isRecipeUnavailable: getIsRecipeUnavailable(
        recipeAvailabilityMap,
        day,
        recipeItems,
        orderNumPortion,
      ),
      daySlotLeadTimes: day.daySlotLeadTimes
        .filter((leadTime) => {
          if (leadTime.isExpress || !leadTime.isSlotDefault) {
            return false
          }

          return true
        })
        .map((leadTime) => ({
          id: leadTime.id,
          isSelected: leadTime.id === selectedDaySlotLeadTimeId,
          startTime: leadTime.startTime,
          endTime: leadTime.endTime,
          deliveryPrice: leadTime.deliveryPrice,
          coreSlotId: leadTime.coreSlotId,
          defaultDay: leadTime.defaultDay,
        })),
    }))
    .filter((day) => {
      return day.daySlotLeadTimes.length > 0
    })

  const selectedSlot = availableSlots?.find((slot) => {
    return slot.date === selectedDeliveryDate
  })

  return {
    availableSlots,
    isLoading,
    selectedSlot,
    setSelectedDaySlotLeadTimeId,
    setDeliveryDate,
  }
}
