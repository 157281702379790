import { useCallback } from 'react'

import moment from 'moment'
import { useSelector } from 'react-redux'

import { fetchDaysForUser } from '@library/api-deliveries'
import { DeliveryPoint } from '@library/api-postcode-lookup'

import { getBasketDate } from 'selectors/basket'
import { getAvailableDeliveryDays, transformDaySlotLeadTimesToMockSlots } from 'utils/deliveries'
import logger from 'utils/logger'

export const useCheckCanDeliver = (
  setPostcodeError: (error: string) => void,
  setDeliveryPoints: (points: DeliveryPoint[]) => void,
  postcode: string,
) => {
  const deliveryDate = useSelector(getBasketDate)
  const menuCutoffUntil = useSelector((state) => (state as any).menuCutoffUntil)

  const checkCanDeliver = useCallback(async () => {
    if (!deliveryDate) {
      setPostcodeError("Sorry, we don't deliver to this postcode yet")
      setDeliveryPoints([])

      return
    }

    const cutOfFrom = moment().startOf('day').toISOString()
    const menuCutoffUntilFallback = moment().startOf('day').add(30, 'days').toISOString()
    const cutOfUntil = menuCutoffUntil || menuCutoffUntilFallback

    try {
      const data = await fetchDaysForUser({
        filters: {
          cutoff_datetime_from: cutOfFrom,
          cutoff_datetime_until: cutOfUntil,
        },
        sort: 'date',
        direction: 'asc',
        postcode,
      })

      const days = transformDaySlotLeadTimesToMockSlots(data)

      const availableDeliveryDays = getAvailableDeliveryDays(days)

      if (
        availableDeliveryDays?.[deliveryDate] &&
        !availableDeliveryDays[deliveryDate].alternateDeliveryDay
      ) {
        setPostcodeError('')

        return
      }
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      logger.error(error)
    }

    setPostcodeError("Sorry, we don't deliver to this postcode yet")
    setDeliveryPoints([])
  }, [deliveryDate, menuCutoffUntil, postcode, setDeliveryPoints, setPostcodeError])

  return { checkCanDeliver }
}
