import React from 'react'

import css from './Arrow.css'

const getArrowClasses = (position: string) => {
  const [positionY, positionX] = position.split('-')

  return `${css.arrow} ${css[`arrow--${positionY}`]} ${positionX ? css[`arrow--${positionX}`] : ''}`
}

type ArrowProps = {
  positionY?: string
  position?: string
  style?: React.CSSProperties
}

const Arrow = ({ positionY = 'top', position = 'top', style = {} }: ArrowProps) =>
  position.includes(positionY) ? (
    <span className={getArrowClasses(position)} style={style}>
      {position.includes('top') ? '▲' : '▼'}
    </span>
  ) : null

export { Arrow, getArrowClasses }
