import React from 'react'

import {
  Display,
  Image,
  Color,
  JustifyContent,
  AlignItems,
  Box,
} from '@gousto-internal/citrus-react'

import loader from 'media/images/spinner.gif'

export function Spinner() {
  return (
    <Box
      display={Display.Flex}
      gap={4}
      width="100%"
      height="48px"
      borderRadius={1.5}
      bg={Color.NeutralGrey_900}
      justifyContent={JustifyContent.Center}
      alignItems={AlignItems.Center}
    >
      <Image src={loader} alt="Loading spinner" height="24px" width="24px" />
    </Box>
  )
}
