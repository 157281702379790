import React, { ReactNode } from 'react'

import {
  AlignItems,
  Box,
  Color,
  Display,
  FlexDirection,
  FontWeight,
  Heading2,
  Link,
  Space,
  Text,
} from '@gousto-internal/citrus-react'

import { queueTrackingEvent } from '@library/tracking-integration'

import { everydayFavouritesHelp, healthHubHelp, speedyMealsHelp } from 'actions/trackingKeys'

import { discountInformationCopy1, discountInformationCopy2 } from '../boxPricesConfig'

export type BoxPricesDiscountInformationProps = {
  children: ReactNode
}

type TrackingBoxPricesDiscountInformation = {
  action: string
}

export const BoxPricesDiscountInformation: React.FC<BoxPricesDiscountInformationProps> = ({
  children,
}) => (
  <Box
    data-testid="box-prices-discount-information"
    display={Display.Flex}
    bg={Color.NeutralGrey_50}
    flexDirection={FlexDirection.Column}
    alignItems={AlignItems.Center}
  >
    <Box data-testid="how-does-discount-work-copy" maxWidth={['343px', '556px']}>
      <Heading2 size={[9, 10]}>{discountInformationCopy1.title}</Heading2>
      <Space size={2} />
      <Text fontWeight={FontWeight.Normal} size={[4, 6]}>
        {discountInformationCopy1.text}
      </Text>
    </Box>

    <Space size={4} />

    {children}

    <Space size={4} />

    <Box data-testid="how-do-I-add-discount-copy" maxWidth={['343px', '556px']}>
      <Heading2 size={[9, 10]}>{discountInformationCopy2.title}</Heading2>
      <Space size={2} />
      <Text fontWeight={FontWeight.Normal} size={[4, 6]}>
        {discountInformationCopy2.text1}
        <Link
          data-testid="speedy-meals-link"
          href="/menu?collection=10-minute-meals"
          onClick={() => {
            queueTrackingEvent<TrackingBoxPricesDiscountInformation>({
              action: speedyMealsHelp,
            })
          }}
        >
          {discountInformationCopy2.speedyMealsLinkText}
        </Link>
        {discountInformationCopy2.text2}
        <Link
          data-testid="health-hub-link"
          href="/menu?collection=health-hub"
          onClick={() => {
            queueTrackingEvent<TrackingBoxPricesDiscountInformation>({
              action: healthHubHelp,
            })
          }}
        >
          {discountInformationCopy2.healthyMealsLinkText}
        </Link>
        {discountInformationCopy2.text3}
        <Link
          data-testid="everyday-favourites-link"
          href="/menu?collection=everyday-favourites"
          onClick={() => {
            queueTrackingEvent<TrackingBoxPricesDiscountInformation>({
              action: everydayFavouritesHelp,
            })
          }}
        >
          {discountInformationCopy2.everydayFavouritesLinkText}
        </Link>
      </Text>
    </Box>

    <Space size={10} />
  </Box>
)
