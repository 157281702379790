import { actionTypes } from 'actions/actionTypes'

const TRACKING_EVENT_NAME = 'profile_navigation_bar_click'

export const trackAccountNavBarClick = (sectionName) => (dispatch) => {
  dispatch({
    type: actionTypes.TRACKING,
    trackingData: {
      actionType: TRACKING_EVENT_NAME,
      section: sectionName
    }
  })
}
