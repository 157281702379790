import React, { useCallback, useState } from 'react'

import {
  Box,
  Heading1,
  Body3,
  Display,
  JustifyContent,
  Color,
  Button,
  ButtonSize,
  ButtonColorVariant,
  Join,
  Space,
  FlexDirection,
  useModal,
} from '@gousto-internal/citrus-react'
import { useDispatch } from 'react-redux'

import { redirect } from 'actions/redirect'
import config from 'config/routes'
import { MULTI_SKIP_MODAL_NAME, MultiSkipScreenContainer } from 'routes/Account/MultiSkipScreen'

import { useZendeskChat } from '../hooks/useZendeskChat'
import { trackSubscriptionSettingsChange } from '../tracking'
import { CHANGE_FREQUENCY_MODAL_NAME, ChangeFrequencyModal } from './ChangeFrequencyModal'
import { GET_HELP_MODAL, GetHelpModal } from './GetHelpModal'
import { PauseReasons, Handler } from './PauseReasons'

interface Props {
  accessToken: string
  handleBackButton: () => void
  handlePauseSubmit: (reason?: string) => void
}

const SubscriptionPauseReasonOSR = ({
  accessToken,
  handlePauseSubmit,
  handleBackButton,
}: Props) => {
  const { openModal } = useModal()
  const [continueButtonEnabled, setContinueButtonEnabled] = useState(false)
  const [selectedReason, setSelectedReason] = useState('')
  const [reasonsToTrackWhenContinuing, setReasonsToTrackWhenContinuing] = useState<{
    pause_reason: string
    secondary_pause_reason?: string
  }>()

  const handleCloseLiveChat = useCallback(() => {
    openModal(GET_HELP_MODAL)
  }, [openModal])
  const { handleChat } = useZendeskChat(handleCloseLiveChat)

  const handleSelectedReason: Handler = useCallback(
    (reason, disableContinueToPause, tracking) => {
      const { reason: trackingReason } = tracking
      const trackingData = tracking?.secondaryReason
        ? {
            pause_reason: trackingReason,
            secondary_pause_reason: tracking.secondaryReason,
          }
        : { pause_reason: trackingReason }

      trackSubscriptionSettingsChange({
        settingName: 'pause_reason',
        action: 'click',
      })(trackingData)

      setReasonsToTrackWhenContinuing(trackingData)
      setSelectedReason(reason)

      if (disableContinueToPause) {
        setContinueButtonEnabled(false)

        return
      }

      setContinueButtonEnabled(true)
    },
    [setContinueButtonEnabled],
  )

  const trackPauseRecoveryAction = useCallback((tracking) => {
    trackSubscriptionSettingsChange({
      settingName: 'pause_recovery_action',
      action: 'click',
    })(tracking)
  }, [])

  const handleChangeDeliveryFrequency = useCallback(
    ({ tracking }) => {
      trackPauseRecoveryAction(tracking)

      openModal(CHANGE_FREQUENCY_MODAL_NAME)
    },
    [trackPauseRecoveryAction, openModal],
  )

  const handleSkipMyDeliveries = useCallback(
    ({ tracking }) => {
      trackPauseRecoveryAction(tracking)

      openModal(MULTI_SKIP_MODAL_NAME)
    },
    [trackPauseRecoveryAction, openModal],
  )

  const dispatch = useDispatch()

  const handleFrequencyChangeCompleted = useCallback(() => {
    dispatch(redirect(config.client.myDetails))
  }, [dispatch])

  const handleOpenLiveChat = useCallback(
    ({ tracking }) => {
      trackPauseRecoveryAction(tracking)

      handleChat()
    },
    [trackPauseRecoveryAction, handleChat],
  )

  const handleBackButtonClicked = useCallback(() => {
    trackSubscriptionSettingsChange({ settingName: 'pause_reason_flow', action: 'bail' })()
    handleBackButton()
  }, [handleBackButton])

  const handleContinue = useCallback(() => {
    trackSubscriptionSettingsChange({
      settingName: 'continue_to_pause',
      action: 'click',
    })(reasonsToTrackWhenContinuing)
    handlePauseSubmit(selectedReason)
  }, [handlePauseSubmit, selectedReason, reasonsToTrackWhenContinuing])

  const handleOtherReason: Handler = useCallback(
    (reason, disableContinueToPause, tracking) => {
      setSelectedReason(reason)

      setReasonsToTrackWhenContinuing({
        pause_reason: tracking.reason,
        secondary_pause_reason: tracking.secondaryReason,
      })

      if (disableContinueToPause) {
        setContinueButtonEnabled(false)

        return
      }

      setContinueButtonEnabled(true)
    },
    [setSelectedReason, setContinueButtonEnabled],
  )

  const mappedHeadingToCancelSubscription = 'Cancel subscription'

  return (
    <>
      <MultiSkipScreenContainer onUpdateComplete={handleFrequencyChangeCompleted} />
      <ChangeFrequencyModal
        accessToken={accessToken}
        onUpdateCompleted={handleFrequencyChangeCompleted}
      />
      <GetHelpModal onPauseSubmit={handlePauseSubmit} onBackButtonClick={handleBackButton} />

      <Box display={Display.Flex} justifyContent={JustifyContent.Center} paddingV={6}>
        <Box width={['90%', '60%']} bg={Color.White} paddingH={[4, 10]} paddingV={[6, 10]}>
          <Box data-testing="SubscriptionPauseReasonOSR-heading">
            <Join with={<Space size={1} />}>
              <Body3 color={Color.ColdGrey_600}>{mappedHeadingToCancelSubscription}</Body3>
              <Heading1 color={Color.ColdGrey_900} size={[9, 11]}>
                Before cancelling, can you tell us why?
              </Heading1>
              <Body3 color={Color.ColdGrey_800}>
                Telling us why will help improve our service in the future.
              </Body3>
            </Join>
          </Box>
          <Box paddingV={[5, 10]}>
            <PauseReasons
              handleChangeDeliveryFrequency={handleChangeDeliveryFrequency}
              handleSkipMyDeliveries={handleSkipMyDeliveries}
              handleOtherReason={handleOtherReason}
              handleSelectedReason={handleSelectedReason}
              handleOpenLiveChat={handleOpenLiveChat}
            />
          </Box>
          <Box
            data-testing="SubscriptionPauseReasonOSR-footer"
            display={Display.Flex}
            flexDirection={[FlexDirection.Column, FlexDirection.Row]}
            justifyContent={JustifyContent.SpaceBetween}
          >
            <Join with={<Space size={[2, 0]} />}>
              <Button
                colorVariant={ButtonColorVariant.Secondary}
                size={ButtonSize.Large}
                onClick={handleBackButtonClicked}
                width={['100%', 'auto']}
              >
                Back
              </Button>
              <Button
                colorVariant={ButtonColorVariant.Primary}
                size={ButtonSize.Large}
                disabled={!continueButtonEnabled}
                onClick={handleContinue}
              >
                Continue to cancel
              </Button>
            </Join>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export { SubscriptionPauseReasonOSR }
