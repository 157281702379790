import React, { useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'

import { useAuth } from 'routes/Menu/domains/auth'
import { useBasket } from 'routes/Menu/domains/basket'
import { trackDisplayEarliestDeliveryDate } from 'routes/Signup/Steps/Delivery/utils/tracking'
import {
  getDeliveryDetailsInstructions,
  getIsValidDeliveryDetailsInstructionsCustom,
} from 'selectors/deliveryDetails'
import { getLandingDay } from 'utils/deliveries'

import { DeliverySlot } from './DeliverySlot'

const DeliverySlotWrapper = (ownProps: any) => {
  const { removeRecipe } = useBasket()
  const { isAuthenticated } = useAuth()
  const { date: earliestDeliveryDate } = useSelector((state) =>
    getLandingDay(state, { cantLandOnOrderDate: true, useBasketDate: false }),
  )
  const deliveryInstruction = useSelector(getDeliveryDetailsInstructions)
  const isValidDeliveryDetailsInstructionCustom = useSelector(
    getIsValidDeliveryDetailsInstructionsCustom,
  )
  const hasDisplayEarliestDeliveryDateEffectRun = useRef(false)
  useEffect(() => {
    if (!hasDisplayEarliestDeliveryDateEffectRun.current && earliestDeliveryDate) {
      const orderType = isAuthenticated ? 'transactional' : 'signup'
      trackDisplayEarliestDeliveryDate(earliestDeliveryDate, orderType)
      hasDisplayEarliestDeliveryDateEffectRun.current = true
    }
  }, [earliestDeliveryDate, isAuthenticated])

  return (
    <DeliverySlot
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...ownProps}
      removeRecipeFromBasket={removeRecipe}
      deliveryInstruction={deliveryInstruction}
      isValidDeliveryDetailsInstructionCustom={isValidDeliveryDetailsInstructionCustom}
    />
  )
}

export { DeliverySlotWrapper }
