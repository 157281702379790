import { Children, cloneElement, PureComponent, ReactElement } from 'react'

import { isElementHidden } from 'routes/Menu/components/Tutorial/helpers'

import { StepProps } from './Step'

type TutorialProps = {
  children: ReactElement<StepProps>[]
  onClose?: (step: number) => void
  trackStepViewed: (step: number) => void
}

type TutorialState = {
  children: ReactElement<StepProps>[]
  step: number
  hide: boolean
}

export class Tutorial extends PureComponent<TutorialProps, TutorialState> {
  constructor(props: TutorialProps) {
    super(props)
    const { children } = props

    const visibleChildren = Children.toArray(children).filter(
      (child): child is ReactElement<StepProps> =>
        !isElementHidden((child as ReactElement<StepProps>).props.selector),
    )

    this.state = {
      children: visibleChildren,
      step: 0,
      hide: Boolean(!visibleChildren.length),
    }
  }

  componentDidMount() {
    const { trackStepViewed } = this.props
    const { step } = this.state
    trackStepViewed(step)
  }

  close = () => {
    const { onClose } = this.props
    const { step } = this.state

    if (onClose) {
      onClose(step)
    }
    this.setState({
      hide: true,
    })
  }

  next = () => {
    const { trackStepViewed } = this.props
    const { step, children } = this.state

    if (step === children.length - 1) {
      this.close()
    } else {
      this.setState(
        {
          step: step + 1,
        },
        () => {
          trackStepViewed(step + 1)
        },
      )
    }
  }

  render() {
    const { children, step, hide } = this.state

    return hide
      ? null
      : cloneElement(children[step], {
          last: step === children.length - 1,
          onClose: this.close,
          next: this.next,
        })
  }
}
