import React from 'react'
import { Button, ButtonColorVariant } from '@gousto-internal/citrus-react'
import PropTypes from 'prop-types'

import { trackSubscriptionSettingsChange } from '../../../tracking'

export const PauseSubscription = ({ handlePause }) => {
  const settingName = 'pause_subscription'
  const mappedCTALabelToCancel = 'Cancel subscription'

  const handleClick = () => {
    trackSubscriptionSettingsChange({ settingName, action: 'cta_clicked' })()
    handlePause()
  }

  return (
    <Button
      data-testid="pause-subscription-cta"
      colorVariant={ButtonColorVariant.Warning}
      onClick={handleClick}
      width="100%"
    >
      {mappedCTALabelToCancel}
    </Button>
  )
}

PauseSubscription.propTypes = {
  handlePause: PropTypes.func.isRequired
}
