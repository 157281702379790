export type SignupDiscount = {
  amount: number
  recurrence: number
}

export type BoxDiscounts = {
  [boxType: string]: SignupDiscount[]
}

// Recurrence is the number of orders that the discount applies to.
// Needs to add up to the total number of orders, which is calculated as
// ORDER_FREQUENCY multiplied by PROMOTION_DURATION.
const discountStages: SignupDiscount[] = [
  { amount: 50, recurrence: 1 },
  { amount: 40, recurrence: 1 },
  { amount: 20, recurrence: 6 },
]

export const PROMOTION_DURATION = 2 // months
export const ORDER_FREQUENCY = 4 // orders per month

export const boxDiscounts: BoxDiscounts = {
  '2x1': discountStages,
  '3x1': discountStages,
  '4x1': discountStages,
  '5x1': discountStages,
  '2x2': discountStages,
  '3x2': discountStages,
  '4x2': discountStages,
  '5x2': discountStages,
  '2x3': discountStages,
  '3x3': discountStages,
  '4x3': discountStages,
  '5x3': discountStages,
  '2x4': discountStages,
  '3x4': discountStages,
  '4x4': discountStages,
  '5x4': discountStages,
  '2x5': discountStages,
  '3x5': discountStages,
}
