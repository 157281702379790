import Decimal from 'decimal.js'

import { boxDiscounts, ORDER_FREQUENCY, PROMOTION_DURATION, SignupDiscount } from './config'

/**
 * Generates a box type string based on size and number of portions.
 * @param boxSize - The size of the box (number of recipes in a box).
 * @param numPortions - The number of portions.
 * @returns The box type string.
 */
function boxType(boxSize: number, numPortions: number): string {
  return `${boxSize}x${numPortions}`
}

/**
 * Calculates the discounted price based on the original price and discount percentage.
 * @param price - The original price.
 * @param discountPercentage - The discount percentage.
 * @returns The discounted price as a Decimal.
 */
function calculateDiscountedPrice(price: number, discountPercentage: number): Decimal {
  return new Decimal(price).minus(new Decimal(price).times(discountPercentage).div(100))
}

/**
 * Calculates the price per portion based on the discounted price, box size, and number of portions.
 * @param discountedPrice - The discounted price.
 * @param numPortions - The number of portions.
 * @param boxSize - The size of the box.
 * @returns The price per portion as a Decimal.
 */
function calculatePricePerPortion(
  discountedPrice: Decimal,
  numPortions: number,
  boxSize: number,
): Decimal {
  return discountedPrice.div(numPortions * boxSize)
}

/**
 * Calculates the total savings over a promotional period.
 * @param normalBoxPrice - The normal price of the box.
 * @param initialDiscountedBoxPrice - The initial promotional discount price of the box.
 * @param recurringDiscountBoxPrice - The recurring promotional discount price of the box.
 * @param promotionDuration - The duration of the promotion in months.
 * @param orderFrequency - The frequency of orders per month.
 * @returns The total savings as a Decimal.
 */
function calculateTotalSavings(
  normalBoxPrice: number,
  discounts: SignupDiscount[],
  promotionDuration = PROMOTION_DURATION,
  orderFrequency = ORDER_FREQUENCY,
): Decimal {
  const numberOfOrders = promotionDuration * orderFrequency
  const totalCostNoDiscount = new Decimal(normalBoxPrice).times(numberOfOrders)

  let totalDiscountedCost = new Decimal(0)
  let remainingOrders = numberOfOrders

  discounts.forEach(({ amount, recurrence }) => {
    const applicableOrders = Math.min(recurrence, remainingOrders)

    const discountedPrice = new Decimal(normalBoxPrice)
      .minus(new Decimal(normalBoxPrice).times(amount).div(100))
      .times(applicableOrders)
    totalDiscountedCost = totalDiscountedCost.plus(discountedPrice)
    remainingOrders -= applicableOrders
  })

  if (remainingOrders > 0) {
    totalDiscountedCost = totalDiscountedCost.plus(
      new Decimal(normalBoxPrice).times(remainingOrders),
    )
  }

  const totalSavings = totalCostNoDiscount.minus(totalDiscountedCost)

  return totalSavings
}

/**
 * Retrieves the prices and savings for a given box size and number of portions.
 * @param boxSize - The size of the box (number of recipes in a box).
 * @param numPortions - The number of portions.
 * @param normalBoxPrice - The normal, non-discounted, price of the box.
 * @returns An object containing the discounted first box price, first box price per portion, and total promotional saving.
 */
export function getPromotionalPricing(
  boxSize: number,
  numPortions: number,
  normalBoxPrice: number,
) {
  const selectedBox = boxType(boxSize, numPortions)
  const promotionalBoxDiscount = boxDiscounts[selectedBox]
  if (!promotionalBoxDiscount) {
    throw new Error(`Box type ${selectedBox} not found`)
  }

  const firstBoxDiscountPrice = calculateDiscountedPrice(
    normalBoxPrice,
    promotionalBoxDiscount[0].amount,
  )
  const firstBoxDiscountPricePerPortion = calculatePricePerPortion(
    firstBoxDiscountPrice,
    numPortions,
    boxSize,
  )

  const totalPromotionalSavings = calculateTotalSavings(normalBoxPrice, promotionalBoxDiscount)

  return {
    firstBoxPriceWithDiscount: firstBoxDiscountPrice.toFixed(2),
    firstBoxPerPortionPriceWithDiscount: firstBoxDiscountPricePerPortion.toFixed(2),
    totalSaving: totalPromotionalSavings.toFixed(2),
  }
}
