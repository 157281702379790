import { composeFetch } from '@library/http'
import { getAuthUserId } from '@library/auth'
import {
  composeRequest,
  addPath,
  setServiceUrl,
  setMethod,
  setQueryParams,
  setHeaders,
  auth,
} from '@library/http/requests'
import { Menu } from './types'

export type MenuRequestParams = {
  date?: string
  deliveryDate?: string
  numPortions: number
  sessionId: string
}

const menuApiMenuParse = async (response: Response): Promise<Menu> => {
  const body = await response.text()
  const parsedBody = JSON.parse(body)

  if (!response.ok) {
    throw new Error(parsedBody.message)
  }

  return parsedBody as Menu
}

const menuApiMenuRequest = composeRequest(
  setServiceUrl('menu', 3),
  addPath(() => 'menus'),
  auth,
  setQueryParams(({ date, deliveryDate, numPortions }: MenuRequestParams) => {
    const userId = getAuthUserId()

    return {
      ...(date && { date }),
      ...(deliveryDate && { delivery_date: deliveryDate }),
      num_portions: numPortions.toString(),
      ...(userId && { user_id: userId }),
    }
  }),
  setHeaders(({ sessionId }) => {
    return {
      ...(sessionId ? { 'x-gousto-device-id': sessionId } : {}),
    }
  }),
  setMethod('GET'),
)

export const menuFetcher = composeFetch(menuApiMenuRequest, menuApiMenuParse)
