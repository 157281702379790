import React from 'react'
import { Box, DisplayPosition } from '@gousto-internal/citrus-react'
import { HeroSlide } from './components/HeroSlide/HeroSlide'

export const Carousel = () => {
  return (
    <Box width="100%" data-testid="carousel">
      <Box position={DisplayPosition.Absolute} width={'100%'}>
        <HeroSlide />
      </Box>
    </Box>
  )
}
