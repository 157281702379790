import React, { useCallback, useEffect, useState } from 'react'

import {
  AlignItems,
  Body2,
  Box,
  ButtonColorVariant,
  ButtonSize,
  Color,
  Display,
  Heading3,
  Heading4,
  JustifyContent,
  Modal,
  ModalBody,
  ModalButton,
  ModalClose,
  ModalFooter,
  ModalImageHeader,
  Space,
  Link,
  withCitrusTheme,
  useModal,
} from '@gousto-internal/citrus-react'
import Loading from 'Loading'
import Svg from 'Svg'

import config from 'config/routes'
import { trackSubscriptionSettingsChange } from 'routes/Account/Subscription/tracking'

import css from './OnScreenRecoveryView.css'

const LoadingWithPadding = withCitrusTheme(Loading, (theme) => ({
  padding: theme.spacing(10),
}))

const BoxIcon = withCitrusTheme(Box, (theme) => ({
  padding: theme.spacing(3),
  borderRadius: '3px',
  background: theme.color(Color.Success_600),
}))

const SvgDiscountIcon = withCitrusTheme(Svg, () => ({
  width: '2rem',
  height: '2rem',
}))

interface Props {
  offer?: {
    message: string
    formattedValue: string
    rawMessage: {
      text: string
      values: {
        date: string
        value: string
      }
    }
  }
  onConfirm: () => void
  onKeep: () => void
  title: string
  triggered: boolean
  modalType: string
  getRecoveryContent: () => void
  modalVisibilityChange: (payload: { modalVisibility: boolean; modalType: string }) => void
  trackViewDiscountReminder: () => void
  isFetching: boolean
  pauseReason?: string
}

export const OnScreenRecoveryView = ({
  offer,
  onConfirm,
  onKeep,
  title,
  modalType,
  triggered,
  getRecoveryContent,
  modalVisibilityChange,
  trackViewDiscountReminder,
  isFetching,
  pauseReason,
}: Props) => {
  const isModalTypeSetToOrder = modalType === 'order'
  const [shouldDisplayModal, setShouldDisplayModal] = useState(triggered)

  const { closeCurrentModal } = useModal()

  const trackForSubscriptionPause = useCallback(
    (trackingConfig: { settingName: string; action: unknown }, data: Record<string, unknown>) => {
      if (!isModalTypeSetToOrder) {
        trackSubscriptionSettingsChange(trackingConfig)(data)
      }
    },
    [isModalTypeSetToOrder],
  )

  const handleAfterModalClose = useCallback(() => {
    modalVisibilityChange({ modalVisibility: false, modalType })
  }, [modalVisibilityChange, modalType])

  const handleModalClose = useCallback(() => {
    trackForSubscriptionPause(
      { settingName: 'recover', action: 'subscription' },
      {
        pause_reason: pauseReason,
        recovery_action: 'close_modal',
      },
    )
    closeCurrentModal()
  }, [closeCurrentModal, trackForSubscriptionPause, pauseReason])

  const handleGoToDeliveriesClick = useCallback(() => {
    trackForSubscriptionPause(
      { settingName: 'recover', action: 'subscription' },
      {
        pause_reason: pauseReason,
        recovery_action: 'Go to manage my deliveries',
      },
    )
  }, [pauseReason, trackForSubscriptionPause])

  const handleKeepClick = useCallback(() => {
    trackForSubscriptionPause(
      { settingName: 'recover', action: 'subscription' },
      {
        pause_reason: pauseReason,
        recovery_action: 'Keep subscription CTA clicked',
        discount_offered: offer?.formattedValue,
      },
    )
    onKeep()
  }, [onKeep, pauseReason, offer, trackForSubscriptionPause])

  const handlePauseClick = useCallback(() => {
    trackForSubscriptionPause(
      { settingName: 'pause_subscription', action: 'complete' },
      {
        pause_reason: pauseReason,
        discount_offered: offer?.formattedValue,
      },
    )

    onConfirm()
  }, [onConfirm, pauseReason, offer, trackForSubscriptionPause])

  useEffect(() => {
    if (triggered) {
      getRecoveryContent()
    }

    setShouldDisplayModal(triggered)
  }, [getRecoveryContent, triggered])

  useEffect(() => {
    if (triggered && offer && !isModalTypeSetToOrder) {
      trackForSubscriptionPause(
        { settingName: 'view_pause_recovery', action: 'discount' },
        {
          discount_offered: offer.formattedValue,
        },
      )
      trackViewDiscountReminder()
    }
  }, [
    trackViewDiscountReminder,
    trackForSubscriptionPause,
    triggered,
    offer,
    isModalTypeSetToOrder,
  ])

  return (
    <Modal isOpen={shouldDisplayModal} name="pause-discount" onAfterClose={handleAfterModalClose}>
      <ModalClose onClose={handleModalClose} />

      <ModalImageHeader>
        <div className={css.backgroundHeader} />
      </ModalImageHeader>

      {isFetching && (
        <ModalBody>
          <Box
            alignItems={AlignItems.Center}
            display={Display.Flex}
            justifyContent={JustifyContent.Center}
            data-testid="loading-osr-modal"
          >
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-ignore TS requires optional props */}
            <LoadingWithPadding />
          </Box>
        </ModalBody>
      )}

      {!isFetching && (
        <>
          <ModalBody>
            <Heading3>{title}</Heading3>
            {offer && (
              <>
                <Space size={5} direction="vertical" />
                <Box
                  display="flex"
                  alignItems={AlignItems.Center}
                  bg={Color.Success_50}
                  paddingH={8}
                >
                  <BoxIcon display="flex" alignItems={AlignItems.Center}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore TS requires optional props */}
                    <SvgDiscountIcon fileName="pause-osr-modal-icon" />
                  </BoxIcon>
                  <Box paddingH={6} paddingV={6}>
                    <Heading4>{offer.message}</Heading4>
                  </Box>
                </Box>
              </>
            )}

            {!isModalTypeSetToOrder && (
              <>
                <Space size={5} direction="vertical" />
                <Heading4>Need more flexibility?</Heading4>
                <Space size={2} direction="vertical" />
                <Body2 data-testid="pause-osr-modal-skip-boxes-msg">
                  Don&apos;t forget you can always skip boxes at any time on your deliveries page.{' '}
                  <Link
                    href={config.client.myDeliveries}
                    onClick={handleGoToDeliveriesClick}
                    size={3}
                  >
                    Go to manage my deliveries page
                  </Link>
                </Body2>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <ModalButton
              size={ButtonSize.Medium}
              colorVariant={ButtonColorVariant.Secondary}
              onClick={handlePauseClick}
              width="100%"
              style={{
                whiteSpace: 'nowrap',
              }}
            >
              {isModalTypeSetToOrder ? 'Skip anyway' : 'Cancel anyway'}
            </ModalButton>
            <ModalButton
              size={ButtonSize.Medium}
              colorVariant={ButtonColorVariant.Primary}
              onClick={handleKeepClick}
            >
              {isModalTypeSetToOrder ? 'Keep order' : 'Keep subscription'}
            </ModalButton>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}
