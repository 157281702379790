import routes from 'config/routes'
import { Pricing } from 'routes/Menu/domains/pricing'
import { PaymentMethod } from 'routes/Signup/signupConfig'
import { getPreviewOrderId } from 'selectors/basket'

import { CheckoutStore } from './models/CheckoutStore'
import { CardProvider } from './models/CheckoutTypes'

export const getCurrentPaymentMethod = (state: CheckoutStore) => state.payment.get('paymentMethod')

export const getPayPalClientToken = (state: CheckoutStore) => state.payment.get('paypalClientToken')

export const getCardToken = (state: CheckoutStore) => state.payment.get('cardPaymentToken')

export const getIsBillingAddressDifferent = (state: CheckoutStore) =>
  state.payment.get('isBillingAddressDifferent')

export const getBillingAddress = (state: CheckoutStore) => state.payment.get('billingAddress')

export const getApplePayToken = (state: CheckoutStore) => state.payment.get('applePayToken')

export const getGooglePayToken = (state: CheckoutStore) => state.payment.get('googlePayToken')

export const getGooglePay3dsTransactionComplete = (state: CheckoutStore) =>
  state.payment.get('isGooglePay3dsTransactionComplete')

export const getChallengeUrl = (state: CheckoutStore) => state.payment.get('challengeUrl')

export const isModalOpen = (state: CheckoutStore) => state.payment.get('isModalVisible')

// there will only be a paypalNonce once the paypal payment is approved
// so we need to rename this to isPayPalPaymentApproved as the current naming is misleading
export const isPayPalReady = (state: CheckoutStore) =>
  getCurrentPaymentMethod(state) === PaymentMethod.PayPal && !!state.payment.get('paypalNonce')

export const isCardPayment = (state: CheckoutStore) =>
  getCurrentPaymentMethod(state) === PaymentMethod.Card

export const isApplePayPayment = (state: CheckoutStore) =>
  getCurrentPaymentMethod(state) === PaymentMethod.ApplePay

export const isGooglePayPayment = (state: CheckoutStore) =>
  getCurrentPaymentMethod(state) === PaymentMethod.GooglePay

export const getCanSubmitPaymentDetails = (state: CheckoutStore) =>
  getCurrentPaymentMethod(state) === PaymentMethod.Card || isPayPalReady(state)

export const getProvider = (state: CheckoutStore) => state.payment.get('provider')

export const getIsApplePayBraintree = (state: CheckoutStore) =>
  state.payment.get('isApplePayBraintree')

export const getPaymentProvider = (state: CheckoutStore) => {
  const method = getCurrentPaymentMethod(state)

  switch (method) {
    case PaymentMethod.Card:
      return getProvider(state)
    case PaymentMethod.PayPal:
      return 'paypal'
    case PaymentMethod.ApplePay:
      return 'bento'
    case PaymentMethod.GooglePay:
      return 'bento'
    default:
      return null
  }
}

export const getCardPaymentDetails = (state: CheckoutStore) => ({
  payment_provider: 'checkout',
  active: 1,
  card_token: getCardToken(state),
})

export const getPayPalPaymentDetails = (state: CheckoutStore) => ({
  payment_provider: 'paypal',
  active: 1,
  token: state.payment.get('paypalNonce'),
  device_data: state.payment.get('paypalDeviceData'),
})

export const getApplePayPaymentDetails = (state: CheckoutStore) => ({
  payment_provider: 'applepay',
  active: 1,
  token: getApplePayToken(state),
})

export const getGooglePayPaymentDetails = (state: CheckoutStore) => ({
  payment_provider: 'googlepay',
  active: 1,
  token: getGooglePayToken(state),
})
export const getPaymentAuthData = (state: CheckoutStore, { pricing }: { pricing: Pricing }) => {
  const { success, failure } = routes.client.payment
  const total = (pricing && pricing.total) || 0
  const amountInPence = Math.round(+total * 100)

  return {
    order_id: state.basket.get('previewOrderId'),
    gousto_ref: state.checkout.get('goustoRef'),
    card_token: getCardToken(state),
    amount: amountInPence,
    '3ds': true,
    success_url: window.location.origin + success,
    failure_url: window.location.origin + failure,
    decoupled: true,
  }
}

export const getPaymentData = (state: CheckoutStore) => {
  const { checkout } = state
  const result = {
    order_id: getPreviewOrderId(state),
    gousto_ref: checkout.get('goustoRef'),
  }
  const payPalPaymentDetails = getPayPalPaymentDetails(state)
  const cardPaymentDetails = getCardPaymentDetails(state)

  if (isApplePayPayment(state)) {
    const applePayPaymentDetails = getApplePayPaymentDetails(state)

    return {
      ...result,
      card_token: applePayPaymentDetails.token,
      '3ds': false,
    }
  }
  if (isGooglePayPayment(state)) {
    const googlePayPaymentDetails = getGooglePayPaymentDetails(state)
    const isGooglePay3dsTransactionComplete = getGooglePay3dsTransactionComplete(state)

    return {
      ...result,
      card_token: googlePayPaymentDetails.token,
      '3ds': isGooglePay3dsTransactionComplete,
    }
  }

  if (
    getProvider(state) === CardProvider.BENTO ||
    getCurrentPaymentMethod(state) === PaymentMethod.PayPal
  ) {
    return {
      ...result,
      card_token: payPalPaymentDetails.token,
      device_data: payPalPaymentDetails.device_data,
      '3ds': getProvider(state) === CardProvider.BENTO,
    }
  }

  return {
    ...result,
    card_token: cardPaymentDetails.card_token,
    '3ds': true,
  }
}
