import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import type { EmblaOptionsType } from 'embla-carousel'
import { Box, Display, DisplayPosition, Overflow, SpaceScale } from '@gousto-internal/citrus-react'

export type CarouselProps = {
  children: React.ReactNode
  options?: EmblaOptionsType
  slidePaddingH?: SpaceScale
  slidePaddingV?: SpaceScale
  slideWidth?: string
  slideHeight?: string
  ariaLabel: string
}

export const Carousel = ({
  children,
  options,
  slidePaddingH,
  slidePaddingV,
  slideWidth,
  slideHeight,
  ariaLabel,
}: CarouselProps) => {
  const [emblaRef] = useEmblaCarousel(options)

  return (
    <Box
      className="embla"
      position={DisplayPosition.Relative}
      overflowX={Overflow.Hidden}
      overflowY={Overflow.Hidden}
    >
      <Box className="embla__viewport" ref={emblaRef}>
        <Box
          aria-label={ariaLabel}
          aria-live="polite"
          aria-atomic="true"
          aria-roledescription="carousel"
          className="embla__container"
          display={Display.Flex}
          role="region"
          tabIndex={0}
        >
          {React.Children.map(children, (child, index) => {
            return (
              <Box
                aria-label={`${index + 1} of ${React.Children.count(children)}`}
                aria-roledescription={`Slide ${index + 1} of ${React.Children.count(children)}`}
                className="embla__slide"
                data-testid="carousel-slide"
                flexBasis={slideWidth}
                flexGrow={0}
                flexShrink={0}
                height={slideHeight}
                key={index}
                paddingH={slidePaddingH}
                paddingV={slidePaddingV}
                role="group"
                tabIndex={-1}
              >
                {child}
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}
