import { endpoint } from '@library/endpoint'

import routes from 'config/routes'
import { fetch } from 'utils/fetch'

export function fetchDeliveryConsignment(accessToken, orderId) {
  const reqData = {
    'filters[order_id]': orderId,
  }

  const url = `${endpoint('deliveries')}${routes.deliveries.consignments}`

  return fetch(accessToken, url, reqData, 'GET')
}
