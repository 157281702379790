import { Image, useTheme } from '@gousto-internal/citrus-react'
import React from 'react'

export type ResponsiveImageProps = {
  mobileSrc: string
  desktopSrc?: string // Optional for desktop images
  alt: string
}

export const ResponsiveImage = ({ mobileSrc, desktopSrc, alt }: ResponsiveImageProps) => {
  const { theme } = useTheme()

  return (
    <picture
      style={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
      }}
    >
      {desktopSrc && (
        <source
          data-testid="desktop-source"
          srcSet={`${desktopSrc} 1x`}
          // From '768px'
          media={`(min-width: ${theme.tokens.breakpoints[1]})`}
        />
      )}

      <source data-testid="mobile-source" srcSet={`${mobileSrc} 1x`} />

      <Image
        data-testid="responsive-image"
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          width: '100%',
          height: '100%',
          borderRadius: '3px',
        }}
        src={mobileSrc}
        alt={alt}
      />
    </picture>
  )
}
