import { useContext } from 'react'

import { OptimizelyContext } from '@optimizely/react-sdk'

import Cookies from 'utils/GoustoCookies'
import logger from 'utils/logger'

export const ENABLE_GOOGLE_PAY_FEATURE_FLAG = 'beetroots_ofx_enable_google_pay'

export function useIsGooglePayEnabled(canPayWithGooglePay: boolean) {
  const { optimizely } = useContext(OptimizelyContext)
  let hasAcceptedAnalytics = false
  try {
    const consentCookieString = Cookies.get('v1_gousto_cookie_consent')
    const consentCookie = consentCookieString ? JSON.parse(consentCookieString) : {}
    hasAcceptedAnalytics = consentCookie?.categories?.includes('ANALYTICS')
  } catch (error) {
    if (error instanceof Error) {
      ;(logger as any).error(error.message)
    }
  }

  const { enabled, variationKey } =
    optimizely && hasAcceptedAnalytics && canPayWithGooglePay
      ? optimizely.decide(ENABLE_GOOGLE_PAY_FEATURE_FLAG)
      : { enabled: false, variationKey: undefined }

  const isEnabled = enabled && variationKey === 't1'

  return isEnabled
}
