import { queueTrackingEvent } from '@library/tracking-integration'

type TrackingAction = 'display_earliest_delivery_date'

type OrderType = 'signup' | 'transactional' | 'subscription'

type TrackingDisplayEarliestDeliveryDate = {
  action: TrackingAction
  properties: {
    delivery_date: string
    order_type: OrderType
  }
}

export function trackDisplayEarliestDeliveryDate(deliveryDate: string, orderType: OrderType) {
  queueTrackingEvent<TrackingDisplayEarliestDeliveryDate>({
    action: 'display_earliest_delivery_date',
    properties: {
      delivery_date: deliveryDate,
      order_type: orderType,
    },
  })
}
