import greenBeansDesktop from './assets/banner/desktop/green-beans.jpg'
import heritageCarrotsDesktop from './assets/banner/desktop/heritage-carrots.jpg'
import heritageTomatoesDesktop from './assets/banner/desktop/heritage-tomatoes.jpg'
import lifestyleShootCoupleOpenBoxDesktop from './assets/banner/desktop/lifestyle-shoot-couple-open-box.jpg'
import lifestyleShootFamilyShareMealDesktop from './assets/banner/desktop/lifestyle-shoot-family-share-meal.jpg'
import lifestyleShootOpenBoxDesktop from './assets/banner/desktop/lifestyle-shoot-open-box.jpg'
import lifestyleShootShareMealDesktop from './assets/banner/desktop/lifestyle-shoot-share-meal.jpg'
import romanoPeppersDesktop from './assets/banner/desktop/romano-peppers.jpg'
import lifestyleShootPeopleCookingDesktop from './assets/banner/desktop/lifestyle-shoot-people-cooking.jpg'

import greenBeansMobile from './assets/banner/mobile/green-beans.jpg'
import heritageCarrotsMobile from './assets/banner/mobile/heritage-carrots.jpg'
import heritageTomatoesMobile from './assets/banner/mobile/heritage-tomatoes.jpg'
import lifestyleShootCoupleOpenBoxMobile from './assets/banner/mobile/lifestyle-shoot-couple-open-box.jpg'
import lifestyleShootFamilyShareMealMobile from './assets/banner/mobile/lifestyle-shoot-family-share-meal.jpg'
import lifestyleShootOpenBoxMobile from './assets/banner/mobile/lifestyle-shoot-open-box.jpg'
import lifestyleShootShareMealMobile from './assets/banner/mobile/lifestyle-shoot-share-meal.jpg'
import romanoPeppersMobile from './assets/banner/mobile/romano-peppers.jpg'
import lifestyleShootPeopleCookingMobile from './assets/banner/mobile/lifestyle-shoot-people-cooking.jpg'

import { BannerImage } from './types/types'

export const CTAs = {
  ctaNoLockInBoldText: 'No lock in: ',
  ctaNoLockInText: 'skip or cancel for free anytime',
  ctaVeggieBoldText: '185+ veggie (incl. vegan) ',
  ctaVeggieText: 'recipes a month',
}

export const HeroSlides = {
  titlePart1: 'The',
  titlePart2: 'recipe box',
  titlePart3: 'with the',
  titlePart4: 'greatest',
  titlePart5: 'choice',
  subTitlePart1: 'Now with over 500 recipes to',
  subTitlePart2: ' choose from every month',
}

export const VeganSlides = {
  titlePart: 'Veggie & vegan',
  subTitlePart1: 'Over 250 recipes',
  subTitlePart2: 'every month including:',
}

const bannerImagesData: BannerImage[] = [
  {
    name: 'Family Share Meal',
    mobileUrl: lifestyleShootFamilyShareMealMobile,
    desktopUrl: lifestyleShootFamilyShareMealDesktop,
    alt: 'Family sharing a meal together at the dinner table',
  },
  {
    name: 'Heritage Carrots',
    mobileUrl: heritageCarrotsMobile,
    desktopUrl: heritageCarrotsDesktop,
    alt: 'Colorful heritage carrots background',
  },
  {
    name: 'Green Beans',
    mobileUrl: greenBeansMobile,
    desktopUrl: greenBeansDesktop,
    alt: 'Fresh green beans background',
  },
  {
    name: 'Couple Open Box',
    mobileUrl: lifestyleShootCoupleOpenBoxMobile,
    desktopUrl: lifestyleShootCoupleOpenBoxDesktop,
    alt: 'Couple opening a meal kit box',
  },
  {
    name: 'People Cooking',
    mobileUrl: lifestyleShootPeopleCookingMobile,
    desktopUrl: lifestyleShootPeopleCookingDesktop,
    alt: 'People cooking in a kitchen',
  },
  {
    name: 'Herritage Tomatoes',
    mobileUrl: heritageTomatoesMobile,
    desktopUrl: heritageTomatoesDesktop,
    alt: 'Fresh heritage tomatoes background',
  },
  {
    name: 'Share Meal',
    mobileUrl: lifestyleShootShareMealMobile,
    desktopUrl: lifestyleShootShareMealDesktop,
    alt: 'Friends sharing a meal at a table',
  },
  {
    name: 'Open Box',
    mobileUrl: lifestyleShootOpenBoxMobile,
    desktopUrl: lifestyleShootOpenBoxDesktop,
    alt: 'Opened meal kit box showing fresh ingredients',
  },
  {
    name: 'Romano Peppers',
    mobileUrl: romanoPeppersMobile,
    desktopUrl: romanoPeppersDesktop,
    alt: 'Fresh red romano peppers background',
  },
]

export const BANNER_CAROUSEL_IMAGES = [
  ...bannerImagesData,
  ...bannerImagesData.map((data) => {
    return {
      ...data,
      name: `${data.name}-2`,
    }
  }), // Repeat the images if needed with new name (keys)
]
