export const resubscribeSection = {
  title: 'Resubscribe',
  subTitle: 'Want to easily receive Weekly, Fortnightly or Monthly boxes, bursting full of flavourful recipes? Reactivate below.',
  pricingTitle: 'Subscribe and save',
  pricingSubTitle: 'Restart your subscription today!',
  pricingBody1: 'And you can edit your settings any time after you\'ve restarted.',
  testingSelector: 'resubscribe-section'
}

export const orderABoxSection = {
  title: 'Order a single box',
  subTitle: 'Don’t fancy subscribing? No problem, you can still order a box whenever you like!',
  testingSelector: 'order-a-box-section'
}

export const yourSubscriptionDetailsSection = {
  title: 'Your subscription details',
  subTitle: 'These settings are for your normal subscription deliveries',
  testingSelector: 'your-subscription-details-section'
}

export const defaultRecipeSettingsSection = {
  title: 'Default recipe settings',
  testingSelector: 'default-recipe-settings-section'
}

export const skipABoxSection = {
  title: 'Skip a box?',
  subTitle: 'Need a break? No problem - we’re here to be as flexible as we can for you. You can skip as many boxes as you see fit.',
  testingSelector: 'skip-a-box-section'
}

const mappedHeadingToCancelSubscription = 'Cancel subscription'

export const pauseSubscriptionSection = {
  title: mappedHeadingToCancelSubscription,
  subTitle: 'We understand that sometimes you need to take a break and we want to make that as easy as we can for you.',
  testingSelector: 'pause-subscription-section'
}
