import { useCallback, useEffect, useState } from 'react'

import { fetchDaysForUser } from '@library/api-deliveries'

type DaySlotLeadTime = {
  dayId: string
  slotId: string
  leadTime: number
  defaultDay: number
  date: string
  isSlotDefault: boolean
  activeForSubscribersOneOff: boolean
}

export type DeliveryDay = {
  id: string
  daySlotLeadTimes: DaySlotLeadTime[]
  date: string
}

type Props = {
  slotId: string
  cutOffDateTimeFrom: string
  cutOffDateTimeUntil: string
  postcode: string
  selectedDate?: string
}

export type DeliveryDays = {
  data: DeliveryDay[] | null
  deliveryDate: string | null
}

/**
 * Loading, Error State, Delivery Day
 */
type UseSubscriptionToDeliveryDays = [boolean, Error | null, DeliveryDays]

export function useSubscriptionToDeliveryDays({
  slotId,
  cutOffDateTimeFrom,
  cutOffDateTimeUntil,
  postcode,
  selectedDate,
}: Props): UseSubscriptionToDeliveryDays {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const [returnedDeliveryDay, setDeliveryDay] = useState<DeliveryDays>({
    data: null,
    deliveryDate: null,
  })

  const tryFetchDays = useCallback(async () => {
    try {
      const data = await fetchDaysForUser({
        filters: {
          cutoff_datetime_from: cutOffDateTimeFrom,
          cutoff_datetime_until: cutOffDateTimeUntil,
        },
        sort: 'date',
        direction: 'asc',
        postcode,
      })

      const defaultDeliveryDays = data.map((day) => ({
        ...day,
        ...day.daySlotLeadTimes.filter((dslt) => dslt.isSlotDefault === true)[0],
      }))

      const deliverySlotMatchedWithSubscriptionSlotId = defaultDeliveryDays
        .filter((dslt) => {
          // slotId is not unique to one DSLT, it is shared among multiple DSLTs
          // in the case of a date is not selected, we will default to the nearest delivery date
          // otherwise, we will match the slotId and date
          if (!selectedDate) {
            return dslt.slotId === slotId && dslt.activeForSubscribersOneOff
          }

          return (
            dslt.slotId === slotId && dslt.date === selectedDate && dslt.activeForSubscribersOneOff
          )
        })
        .sort((a, b) => a.leadTime - b.leadTime)

      setDeliveryDay({
        data: defaultDeliveryDays,
        deliveryDate: deliverySlotMatchedWithSubscriptionSlotId[0]?.date ?? null,
      })
    } catch (err: unknown) {
      setError(err as Error)
    } finally {
      setLoading(false)
    }
  }, [cutOffDateTimeFrom, cutOffDateTimeUntil, postcode, selectedDate, slotId])

  useEffect(() => {
    tryFetchDays()
  }, [tryFetchDays])

  return [loading, error, returnedDeliveryDay]
}
