import { browserCookies } from '@gousto-internal/storage-consent-web-package'

export const persistenceConfig = (store) => {
  const [hasConsentedTrackingCookie] = browserCookies.getString('v1_goustoStateStore_tracking')

  let persistentCookies = {
    features: false,
    promoAgeVerified: false,
    variants: false,
    basket: {},
    menu: {},
    deliveryDetails: {},
    serverRedirectedFrom: {
      url: true,
      processed: true,
    },
  }

  if (hasConsentedTrackingCookie) {
    persistentCookies.tracking = false
  }

  if (!store.basket.get('orderId')) {
    persistentCookies = Object.assign(persistentCookies, {
      /* one layer deep only, add the resulting cookie names
      to CookieGuard.php in underscore form */
      basket: {
        postcode: true,
        date: true,
        address: false,
        numPortions: true,
        slotId: true,
        recipes: false,
        previewOrderId: true,
        stepsOrder: false,
        collection: true,
        promoCode: true,
        subscriptionOption: true,
      },
      menu: {
        selectedRecipeVariants: false,
      },
      deliveryDetails: {
        deliveryInstruction: true,
        deliveryInstructionCustom: true,
      },
    })
  }

  return persistentCookies
}

export const cookiePrefix = 'goustoStateStore'

export const cookieExpiries = {
  default: 2 / 24,
  features: 7,
  tracking: 30,
  basket_numPortions: 7,
  basket_postcode: 7,
  basket_promoCode: 60,
  menu_selectedRecipeVariants: 7,
  deliveryDetails_deliveryInstruction: 7,
  deliveryDetails_deliveryInstructionCustom: 7,
  serverRedirectedFrom_url: 1 / 24 / 60,
  serverRedirectedFrom_processed: 1 / 24 / 60
}
