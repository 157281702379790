import { datadogLogs } from '@datadog/browser-logs'
import * as braintree from 'braintree-web'

import { ERROR_MESSAGES, GB_COUNTRY_CODE, SHIPPING_ADDRESS_UNSERVICEABLE } from './constants'

type ValidateShippingData = {
  intermediatePaymentData: google.payments.api.IntermediatePaymentData
  postCode: string
}

type PaymentData = {
  googlePayInstance: braintree.GooglePayment
  paymentData: google.payments.api.PaymentData
}

function normalizePostCode(postCode: string): string {
  return postCode.replace(/\s+/g, '').toUpperCase()
}

/**
 * Handles changes to the payment data and returns an error response if address is unserviceable.
 * @param intermediatePaymentData - The payment data containing intermediate payment data and postcode
 * in UK, we only get the first three characters of the post code
 * https://developers.google.com/pay/api/web/reference/response-objects#IntermediateAddress
 * @param postCode - post code passed to us by the user during signup journey.
 * @returns An error response if the shipping address is unserviceable, otherwise an empty object
 */

export function validateShipping({
  intermediatePaymentData,
  postCode,
}: ValidateShippingData): google.payments.api.PaymentDataRequestUpdate | Record<string, any> {
  const { shippingAddress, callbackTrigger } = intermediatePaymentData
  const basketPostCode = normalizePostCode(postCode)
  const googlePayPostCode = normalizePostCode(shippingAddress?.postalCode || '')

  if (callbackTrigger === 'INITIALIZE' || callbackTrigger === 'SHIPPING_ADDRESS') {
    if (shippingAddress?.countryCode !== GB_COUNTRY_CODE) {
      return {
        error: {
          reason: SHIPPING_ADDRESS_UNSERVICEABLE,
          intent: 'SHIPPING_ADDRESS',
          message: ERROR_MESSAGES.nonUkAddress,
        },
      }
    }

    if (!basketPostCode.startsWith(googlePayPostCode)) {
      return {
        error: {
          reason: SHIPPING_ADDRESS_UNSERVICEABLE,
          intent: 'SHIPPING_ADDRESS',
          message: ERROR_MESSAGES.postcodeMismatch,
        },
      }
    }
  }

  return {}
}

export function buildPaymentRequestConfiguration(
  baseRequest: google.payments.api.PaymentDataRequest,
) {
  const enhancedPaymentMethod = {
    ...baseRequest.allowedPaymentMethods[0],
    parameters: {
      ...baseRequest.allowedPaymentMethods[0].parameters,
      billingAddressRequired: true,
      billingAddressParameters: {
        format: 'FULL',
        phoneNumberRequired: true,
      },
    },
  }

  return {
    ...baseRequest,
    allowedPaymentMethods: [enhancedPaymentMethod],
    shippingAddressRequired: true,
    shippingAddressParameters: {
      allowedCountryCodes: ['GB'],
      phoneNumberRequired: true,
    },
    callbackIntents: ['SHIPPING_ADDRESS'] as google.payments.api.CallbackIntent[],
  }
}

export function transformFullRequest(fullRequest: google.payments.api.PaymentDataRequest) {
  const cardOnlyPaymentMethod = fullRequest.allowedPaymentMethods.filter(
    (method: any) => method.type !== 'PAYPAL',
  )

  const enhancedRequest = buildPaymentRequestConfiguration(fullRequest)

  return {
    ...enhancedRequest,
    allowedPaymentMethods: cardOnlyPaymentMethod,
  }
}

export async function parseGooglePayNonce({ googlePayInstance, paymentData }: PaymentData) {
  try {
    return await googlePayInstance.parseResponse(paymentData)
  } catch (error: any) {
    datadogLogs.logger.error('Failed to parse GooglePay nonce', undefined, error)

    return null
  }
}
