import {
  Box,
  Display,
  FlexDirection,
  Color,
  BorderStyle,
  Grid,
  Col,
  Join,
  Space,
  Button,
  JustifyContent,
  AlignItems,
  Body2,
  Body3,
  FontWeight,
  Heading5,
  withCitrusTheme,
} from '@gousto-internal/citrus-react'
import React, { useCallback } from 'react'
import { DiscountPercentage } from './Discount'
import { OrderDetailExpandButton } from './OrderDetail'
import { RecipeImageTiles } from './RecipeImageTiles'
import { useOrderTileContext } from '../hooks/useOrderTileContext'
import { ChosenForYouTile } from './ChosenForYouTile'
import { useTrackingContext } from '../hooks/useTrackingContext'
import { browserHistory } from 'react-router'
import { OrderExpandedWrapper } from './OrderExpandedWrapper'
import { ErrorBoundary } from 'react-error-boundary'
import { Notification } from './Notification'
import { datadogLogs } from '@datadog/browser-logs'

type Props = {
  handleRestoreRecipes: () => void
  isOrderExpanded: boolean
  setIsOrderExpanded: (isExpanded: boolean) => void
  shouldRestoreOrder: boolean
}

const StyledButton = withCitrusTheme(Button, () => ({
  width: '100%',
}))

const StyledBox = withCitrusTheme(Box, () => ({
  paddingTop: '1rem',
}))
export const OrderTileWithoutRecipes = ({
  handleRestoreRecipes,
  isOrderExpanded,
  setIsOrderExpanded,
  shouldRestoreOrder,
}: Props) => {
  const context = useOrderTileContext()
  const { trackChooseRecipes, trackRestoreOrder, trackExpandOrder } = useTrackingContext()
  const orderId = context.orderId

  const handleChooseRecipes = useCallback(() => {
    trackChooseRecipes()
    browserHistory.push(`/menu/${orderId}`)
  }, [trackChooseRecipes, orderId])

  const handleLocalRestoreRecipes = useCallback(() => {
    trackRestoreOrder()
    handleRestoreRecipes()
  }, [handleRestoreRecipes, trackRestoreOrder])

  const renderChosenForYouError = ({ error }: { error: Error }) => {
    datadogLogs.logger.error(`Failed when getting the top picks for order id: ${orderId}. ${error}`)

    return (
      <Notification
        state="error"
        message="An error occurred when getting the top picks for you. Please try again later."
      />
    )
  }

  return (
    <Box
      width={'100%'}
      display={Display.Flex}
      flexDirection={FlexDirection.Column}
      borderWidth={0.5}
      borderColor={Color.ColdGrey_100}
      borderStyle={BorderStyle.Solid}
      paddingV={[3, 8]}
      paddingH={[3, 8]}
      bg={Color.White}
    >
      <Grid width="100%" gap={[0, 2, 8]}>
        <Col size={[12, 12, 7]}>
          <Box display={'flex'} flexDirection={FlexDirection.Column}>
            <Join with={<Space size={[4, 6]} direction="vertical" />}>
              <Box
                display={'flex'}
                flexDirection={FlexDirection.Row}
                justifyContent={JustifyContent.SpaceBetween}
              >
                <Box>
                  <Heading5>{context.humanDeliveryDate}</Heading5>
                  <Body2 color={Color.Warning_700} fontWeight={FontWeight.Bold}>
                    {context.orderPhaseContent}
                  </Body2>
                  <Body3
                    color={Color.ColdGrey_800}
                  >{`Choose by ${context.shouldCutOffTime} on ${context.shouldCutOffDate}`}</Body3>
                </Box>
                <Box display={['flex', 'flex', 'none']} flexDirection={FlexDirection.Row}>
                  {context.isOrderRestorable ? null : <DiscountPercentage />}
                  <OrderDetailExpandButton
                    isOrderExpanded={isOrderExpanded}
                    setIsOrderExpanded={setIsOrderExpanded}
                    trackExpandOrder={trackExpandOrder}
                  />
                </Box>
              </Box>
              <RecipeImageTiles />
              <Box display={['none', 'none', 'flex']}>
                {context.isOrderRestorable ? (
                  <StyledButton disabled={shouldRestoreOrder} onClick={handleLocalRestoreRecipes}>
                    Restore delivery
                  </StyledButton>
                ) : (
                  <StyledButton onClick={handleChooseRecipes}>Choose recipes</StyledButton>
                )}
              </Box>
            </Join>
          </Box>
        </Col>
        <Col size={[12, 12, 4]}>
          <ErrorBoundary fallbackRender={renderChosenForYouError}>
            <ChosenForYouTile
              orderId={context.orderId}
              deliveryDate={context.deliveryDate}
              numPortions={context.box.numPortions}
            />
          </ErrorBoundary>
          <StyledBox display={['flex', 'flex', 'none']}>
            {context.isOrderRestorable ? (
              <StyledButton disabled={shouldRestoreOrder} onClick={handleLocalRestoreRecipes}>
                Restore delivery
              </StyledButton>
            ) : (
              <StyledButton onClick={handleChooseRecipes}>Choose recipes</StyledButton>
            )}
          </StyledBox>
        </Col>
        <Col size={[12, 12, 1]}>
          <Box
            display={['none', 'none', 'flex']}
            flexDirection={FlexDirection.Column}
            justifyContent={JustifyContent.FlexStart}
            alignItems={AlignItems.Center}
            height={'100%'}
          >
            {context.isOrderRestorable ? null : <DiscountPercentage />}
            <OrderDetailExpandButton
              isOrderExpanded={isOrderExpanded}
              setIsOrderExpanded={setIsOrderExpanded}
              trackExpandOrder={trackExpandOrder}
            />
          </Box>
        </Col>
      </Grid>
      {isOrderExpanded && <OrderExpandedWrapper orderId={orderId} />}
    </Box>
  )
}
