import React, { MouseEvent, useCallback, useState } from 'react'

import {
  BorderStyle,
  Box,
  Color,
  Display,
  FlexDirection,
  Space,
  Visible,
} from '@gousto-internal/citrus-react'
import { HotjarTrigger } from 'HotjarTrigger'
import * as braintree from 'braintree-web'
import { Form, FormikProvider } from 'formik'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { CheckoutButton } from 'routes/Checkout/Components/CheckoutButton'
import { DeliveryEducationBanner } from 'routes/Checkout/Components/Delivery/DeliveryEducationBanner'
import { DeliveryInfoCard } from 'routes/Checkout/Components/DeliveryDetailsForm/DeliveryInfoCard'
import {
  FirstNameInput,
  LastNameInput,
  PhoneInput,
} from 'routes/Checkout/Components/DeliveryDetailsForm/Fields'
import { useDeliveryDetailsForm } from 'routes/Checkout/Components/DeliveryDetailsForm/useDeliveryDetailsForm'
import { PostcodeAddressFields } from 'routes/Checkout/Components/PostcodeAddressFields'
import { SectionHeader } from 'routes/Checkout/Components/SectionHeader'
import {
  setCurrentPaymentMethod,
  trackCheckoutButtonPressed,
} from 'routes/Checkout/checkoutActions'
import { CheckoutStepIds } from 'routes/Checkout/checkoutConfig'
import { getIsMobile } from 'routes/Checkout/checkoutSelectors'
import { useIsApplePayEnabled } from 'routes/Checkout/hooks/useIsApplePayEnabled'
import { useIsGooglePayEnabled } from 'routes/Checkout/hooks/useIsGooglePayEnabled'
import { usePricing } from 'routes/Menu/domains/pricing'
import { PaymentMethod } from 'routes/Signup/signupConfig'

import { ExpressCheckoutPayWithApplePay } from './ApplePay'
import {
  ApplePayFallBack,
  errorBoundaryLogger,
  ExpressCheckout,
  ExpressCheckoutDivider,
  ExpressCheckoutHeader,
  GooglePayFallBack,
  useBraintreeApplePayExpressCheckout,
} from './ExpressCheckout'
import { GooglePay } from './GooglePay'

type DeliveryDetailsFormProps = {
  onStepChange: (stepId: CheckoutStepIds) => void
  braintreeClientInstance: braintree.Client | null
  googlePayInstance: braintree.GooglePayment | null
  threeDSecureInstance: braintree.ThreeDSecure | null
}

export const DeliveryDetailsForm = ({
  onStepChange,
  braintreeClientInstance,
  googlePayInstance,
  threeDSecureInstance,
}: DeliveryDetailsFormProps) => {
  const dispatch = useDispatch()
  const isMobile = useSelector(getIsMobile)
  const { pricing } = usePricing()
  const [isReadyToPay, setIsReadyToPay] = useState(false)

  const { handleSubmit, canMakePaymentsWithActiveCard } = useBraintreeApplePayExpressCheckout({
    braintreeClientInstance,
  })

  const isApplePayEnabled = useIsApplePayEnabled()
  const isGooglePayEnabled = useIsGooglePayEnabled(isReadyToPay)

  const isReadyToPayWithApple = isApplePayEnabled && canMakePaymentsWithActiveCard

  const formik = useDeliveryDetailsForm(onStepChange)

  const onCheckoutButtonClick = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      dispatch({
        type: actionTypes.CHECKOUT_CONTINUE_TO_PAYMENT,
        trackingData: { totalPrice: pricing?.total },
      }) // for data layer tracking

      dispatch(setCurrentPaymentMethod(PaymentMethod.Card))

      if (isMobile) {
        dispatch(
          trackCheckoutButtonPressed('DeliveryAddress Confirmed', {
            succeeded: formik.isValid,
            missing_field: formik.isValid
              ? null
              : Object.keys(formik.errors).filter((key) => Boolean((formik.errors as any)[key])),
          }),
        )
      }
      formik.submitForm()
    },
    [dispatch, formik, isMobile, pricing?.total],
  )

  return (
    <Box
      data-testid="checkoutDeliverySection"
      borderStyle={BorderStyle.Solid}
      borderWidth={0.5}
      borderColor={Color.ColdGrey_100}
      bg={Color.White}
      paddingH={[3, 6]}
      paddingV={[6, 8]}
    >
      <ExpressCheckout>
        {(isReadyToPayWithApple || isGooglePayEnabled) && <ExpressCheckoutHeader />}
        <Box display={Display.Flex} flexDirection={FlexDirection.Column}>
          {isReadyToPayWithApple && (
            <ErrorBoundary
              FallbackComponent={ApplePayFallBack}
              onError={() => errorBoundaryLogger('Apple Pay')}
            >
              <ExpressCheckoutPayWithApplePay initializeApplePay={handleSubmit} />
            </ErrorBoundary>
          )}
          <Visible display={isGooglePayEnabled ? 'block' : 'none'}>
            <ErrorBoundary
              FallbackComponent={GooglePayFallBack}
              onError={() => errorBoundaryLogger('Google Pay')}
            >
              <GooglePay
                threeDSecureInstance={threeDSecureInstance}
                googlePayInstance={googlePayInstance}
                setIsReadyToPay={setIsReadyToPay}
              />
            </ErrorBoundary>
          </Visible>
        </Box>
        {(isReadyToPayWithApple || isGooglePayEnabled) && <ExpressCheckoutDivider />}
      </ExpressCheckout>
      <Space size={2} />
      <SectionHeader title="Delivery details" />
      <DeliveryInfoCard />

      <FormikProvider value={formik}>
        <Form autoComplete="off">
          <Box display={Display.Flex}>
            <Box width="100%">
              <FirstNameInput />
            </Box>

            <Space size={4} direction="horizontal" />

            <Box width="100%">
              <LastNameInput />
            </Box>
          </Box>

          <Space size={6} direction="vertical" />

          <PostcodeAddressFields />

          <Box paddingBottom={[4, 6]}>
            <PhoneInput />
          </Box>

          <DeliveryEducationBanner />

          <CheckoutButton isDisabled={!formik.isValid} onClick={onCheckoutButtonClick}>
            Continue to Payment
          </CheckoutButton>
        </Form>
      </FormikProvider>

      <HotjarTrigger name="pbc_delivery_and_payment" shouldInvoke={false} />
    </Box>
  )
}
