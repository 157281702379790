import React, { useEffect, useRef, useState } from 'react'

import { Recaptcha } from 'Recaptcha'
import { NullableReCAPTCHA } from 'Recaptcha/useChallengeClosingObserver'
import { useSelector, useDispatch } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import statusActions from 'actions/status'
import { ErrorMessage } from 'routes/Checkout/Components/ErrorMessage/ErrorMessage'
import { loginAfterSignup, openLoginModal } from 'routes/Checkout/checkoutActions'
import { getExpressCheckoutRecaptchaExecutionStarted } from 'routes/Checkout/checkoutSelectors'

export function ExpressCheckout({ children }: { children: React.ReactNode }) {
  // ReCAPTCHA related fields
  const recaptchaRef = useRef<NullableReCAPTCHA>(null)
  const [hasGotRecaptchaToken, setHasGotRecaptchaToken] = useState(false)
  const recaptchaExecutionStarted = useSelector(getExpressCheckoutRecaptchaExecutionStarted)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!recaptchaExecutionStarted) return

    if (recaptchaRef.current) recaptchaRef.current.execute()
    // in case feature isRecaptchaEnabled is switched off
    else dispatch(loginAfterSignup())
  }, [dispatch, recaptchaExecutionStarted])

  const onRecaptchaTokenChange = (token: string | null) => {
    // to react only on first reCAPTCHA token (when token is expired, this method is called with null)
    if (hasGotRecaptchaToken) return

    setHasGotRecaptchaToken(true)
    dispatch(loginAfterSignup(token))
  }

  const onRecaptchaChallengeClose = () => {
    if (!hasGotRecaptchaToken) {
      dispatch(statusActions.error(actionTypes.CHECKOUT_SIGNUP_LOGIN, true))
    }
  }

  return (
    <>
      {children}
      <ErrorMessage onLoginClick={(e) => dispatch(openLoginModal(e))} />
      <Recaptcha
        ref={recaptchaRef}
        onChange={onRecaptchaTokenChange}
        onChallengeClose={onRecaptchaChallengeClose}
      />
    </>
  )
}
