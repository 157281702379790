import { composeFetch, composeParser } from '@library/http'
import {
  addPath,
  auth,
  composeRequest,
  setHeaders,
  setMethod,
  setQueryParams,
  setServiceUrl,
} from '@library/http/requests'
import { parseGoustoBasicResponse } from '@library/http/src/parsers'
import { DaysResponse } from './interfaces'
import { getSessionItem } from '@library/environment/browser'

type QueryParam = {
  filters: { cutoff_datetime_from: string; cutoff_datetime_until: string }
  sort: string
  direction: string
  postcode?: string
  order_type?: string
}

const fetchDaysForUser = composeFetch(
  composeRequest<QueryParam>(
    setServiceUrl('deliveries', 1),
    addPath('days'),
    setMethod('GET'),
    auth,
    setQueryParams((params: QueryParam) => ({
      'filters[cutoff_datetime_from]': params.filters.cutoff_datetime_from,
      'filters[cutoff_datetime_until]': params.filters.cutoff_datetime_until,
      sort: params.sort,
      direction: params.direction,
      ...(params.postcode && { postcode: params.postcode }),
      ...(params.order_type && { order_type: params.order_type }),
    })),
    setHeaders(() => {
      const testEmailAddress = getSessionItem('test_email_address')
      return {
        ...(testEmailAddress ? { 'x-gousto-test-email-address': testEmailAddress } : {}),
      }
    }),
  ),
  composeParser(
    parseGoustoBasicResponse,
    (basicResponse: unknown) => basicResponse as DaysResponse,
    (response: DaysResponse) => response.data,
  ),
)

export { fetchDaysForUser }
