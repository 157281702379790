import React from 'react'
import {
  AlignItems,
  Bold,
  Box,
  Button,
  Color,
  colors,
  Display,
  FlexDirection,
  Icon,
  JustifyContent,
  Space,
  Text,
} from '@gousto-internal/citrus-react'
import { CTAs } from '../../config'
import { Sections, trackCTAClick, TrackingActions } from '../../tracking'

export type CTAProps = {
  onCTAClick: () => void
  signupCTA: string
}

export function CTA({ onCTAClick, signupCTA }: CTAProps) {
  const { ctaNoLockInBoldText, ctaNoLockInText, ctaVeggieBoldText, ctaVeggieText } = CTAs

  const handleAndTrackCTAClick = () => {
    onCTAClick()
    trackCTAClick(TrackingActions.CLICK_GET_STARTED, Sections.HERO)
  }

  return (
    <Box data-testid="cta">
      <Space size={4} />
      <Box
        display={Display.Flex}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
      >
        <Button onClick={handleAndTrackCTAClick} width={['min(380px, 92%)', '343px']} height="48px">
          {signupCTA}
        </Button>
      </Box>
      <Space size={3} />
      <Box
        display={Display.Flex}
        alignItems={AlignItems.Center}
        justifyContent={JustifyContent.Center}
        flexDirection={FlexDirection.Column}
      >
        <Box
          display={Display.Flex}
          alignItems={AlignItems.Center}
          width={['min(380px, 92%)', '350px', '342px']}
          justifyContent={JustifyContent.Center}
        >
          <Icon name="play_pause" size={6} style={{ color: colors[Color.White] }} />
          <Space direction="horizontal" size={2} />
          <Text size={2} color={Color.White}>
            <Bold>{ctaNoLockInBoldText}</Bold>
            {ctaNoLockInText}
          </Text>
        </Box>
        <Space size={1} />
        <Box
          display={Display.Flex}
          alignItems={AlignItems.Center}
          width={['min(380px, 92%)', '350px', '342px']}
          justifyContent={JustifyContent.Center}
        >
          <Icon name="5_a_day" size={6} style={{ color: colors[Color.White] }} />
          <Space direction="horizontal" size={2} />
          <Text size={2} color={Color.White}>
            <Bold>{ctaVeggieBoldText}</Bold>
            {ctaVeggieText}
          </Text>
        </Box>
      </Box>
      <Space size={4} />
    </Box>
  )
}
