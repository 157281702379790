import { useCallback } from 'react'

import { datadogLogs } from '@datadog/browser-logs'
import * as braintree from 'braintree-web'
import { useDispatch } from 'react-redux'

import { actionTypes } from 'actions/actionTypes'
import { fireCheckoutError } from 'routes/Checkout/checkoutActions'

import { VerifyUserCardPayload, VerifyUserCardStatus } from './types'

export function useThreeDSecure(
  threeDSecureInstance: braintree.ThreeDSecure | null,
  setIsSubmitting: (isSubmitting: boolean) => void,
) {
  const dispatch = useDispatch()

  const handleCustomerCancelledThreeDSecure = useCallback(() => {
    if (!threeDSecureInstance) return
    dispatch(fireCheckoutError(actionTypes.CUSTOMER_CANCELLED_THREEDSECURE))
    setIsSubmitting(false)
    threeDSecureInstance.cancelVerifyCard(() =>
      datadogLogs.logger.error('GooglePay - Customer cancelled ThreeDSecure'),
    )
  }, [dispatch, setIsSubmitting, threeDSecureInstance])

  const handleLookUpComplete = useCallback(
    (data, next) => {
      if (data?.requiresUserAuthentication || data?.threeDSecureInfo?.liabilityShifted) {
        next()
      } else {
        dispatch(fireCheckoutError(actionTypes.CUSTOMER_NOT_ENROLLED_IN_THREEDSECURE))
        setIsSubmitting(false)
        threeDSecureInstance?.cancelVerifyCard(() =>
          datadogLogs.logger.error(
            `GooglePay- Verify card cancelled with status ${data.paymentMethod.threeDSecureInfo.status}`,
          ),
        )
      }
    },
    [dispatch, setIsSubmitting, threeDSecureInstance],
  )

  const isCardAuthenticationSuccessful = useCallback(
    (threeDSecureResult: braintree.ThreeDSecureVerifyPayload) => {
      const { threeDSecureInfo } = threeDSecureResult
      if (threeDSecureInfo.liabilityShiftPossible) {
        if (threeDSecureInfo.liabilityShifted) {
          return true
        } else {
          dispatch(fireCheckoutError(actionTypes.SIGNUP_PAYMENT_FAILED))

          return false
        }
      } else {
        dispatch(fireCheckoutError(actionTypes.VALID_CARD_DETAILS_NOT_PROVIDED))

        return false
      }
    },
    [dispatch],
  )
  const verifyUserCard = useCallback(
    async (payload: VerifyUserCardPayload): Promise<VerifyUserCardStatus> => {
      try {
        if (!threeDSecureInstance) return { success: false }
        threeDSecureInstance.on('lookup-complete', handleLookUpComplete)
        threeDSecureInstance.on('customer-canceled', handleCustomerCancelledThreeDSecure)

        const result = await threeDSecureInstance.verifyCard({
          ...payload,
          challengeRequested: true,
          amount: '0.0' as any,
          additionalInformation: {
            acsWindowSize: '03',
          },
        })

        if (isCardAuthenticationSuccessful(result)) {
          return { success: true, nonce: result.nonce }
        } else {
          setIsSubmitting(false)

          return { success: false }
        }
      } catch (error) {
        dispatch(fireCheckoutError(actionTypes.VALID_CARD_DETAILS_NOT_PROVIDED))
        datadogLogs.logger.error(
          'GooglePay threeds secure failed',
          undefined,
          error instanceof Error ? error : new Error(String(error)),
        )
        setIsSubmitting(false)

        return { success: false }
      }
    },
    [
      threeDSecureInstance,
      handleLookUpComplete,
      handleCustomerCancelledThreeDSecure,
      isCardAuthenticationSuccessful,
      dispatch,
      setIsSubmitting,
    ],
  )

  return {
    verifyUserCard,
    handleCustomerCancelledThreeDSecure,
    handleLookUpComplete,
    isCardAuthenticationSuccessful,
  }
}
