import React, { useCallback, useContext, useEffect, useState } from 'react'

import {
  Body2,
  Bold,
  Box,
  Button,
  ButtonColorVariant,
  Color,
  Display,
  FlexDirection,
  Heading1,
  Join,
  JustifyContent,
  Space,
} from '@gousto-internal/citrus-react'

import { endpoint } from '@library/endpoint'

import routes from 'config/routes'
import { fetch } from 'utils/fetch'

import { SubscriptionContext } from '../context'
import { actionTypes } from '../context/reducers'
import { getSelectedBoxSize } from '../context/selectors/box'
import { getCurrentUserId, getCurrentUserPostcode } from '../context/selectors/currentUser'
import { getCurrentDeliverySlot, getDeliveryFrequency } from '../context/selectors/deliveries'
import { useUpdateSubscription } from '../hooks/useUpdateSubscription'
import { trackSubscriptionSettingsChange } from '../tracking'
import { BoxSizeSection } from './components/BoxSizeSection'
import { FrequencySection } from './components/FrequencySection'
import { InformationComponent } from './components/InformationComponent'
import { NextDeliverySection } from './components/NextDeliverySection'
import { useSubscriptionToDeliveryDays } from './hooks/useSubscriptionToDeliveryDays'
import { SubscriptionResponse, FrequencyKey } from './types'
import { dateFormatter } from './utils/dateFormatter'

export type ResubscribeProps = {
  accessToken: string
  onBackClick: () => void
  onSubmitCompleted: () => void
}

type SubscriptionErrorResponse = {
  status: number
  errors: string[]
}

export const Resubscribe = ({ accessToken, onBackClick, onSubmitCompleted }: ResubscribeProps) => {
  const dateFormatterModule = dateFormatter()
  const formattedCutOffDates = dateFormatterModule.getFormattedCutOffDates(undefined)
  const { state, dispatch } = useContext<any>(SubscriptionContext)

  const postcode = getCurrentUserPostcode(state)
  const userId = getCurrentUserId(state)
  const chosenBoxSize = getSelectedBoxSize(state)
  const currentDeliveryFrequency = getDeliveryFrequency(state) as FrequencyKey
  const {
    id: slotId,
    defaultDay,
    deliveryStartTime,
    deliveryEndTime,
  } = getCurrentDeliverySlot(state) || {}
  const selectedDate = state?.slots?.selectedDate

  const [, , subscriptionToDays] = useSubscriptionToDeliveryDays({
    slotId,
    cutOffDateTimeFrom: formattedCutOffDates.cutOffFrom,
    cutOffDateTimeUntil: formattedCutOffDates.cutOffUntil,
    postcode,
    selectedDate,
  })

  const subscriptionDeliveryDate = subscriptionToDays?.deliveryDate

  const [shouldUpdateSubscription, setShouldUpdateSubscription] = useState(false)
  const [isActivatingSubscription, setIsActivatingSubscription] = useState<boolean>(false)
  const [isErrorPanelVisible, setIsErrorPanelVisible] = useState<boolean>(false)
  const [activateSubsErrorMessage, setActivateSubsErrorMessage] = useState<string>(
    "We're really sorry, but it seems there's an unexpected issue on our end: we're currently unable to update your subscription.",
  )

  const [updateLoading, updateResponse, updateError] = useUpdateSubscription({
    accessToken,
    data: {
      numPortions: chosenBoxSize,
      interval: currentDeliveryFrequency,
      deliverySlotDay: defaultDay,
      deliverySlotStartTime: deliveryStartTime,
      deliverySlotEndTime: deliveryEndTime,
    },
    trigger: {
      shouldRequest: shouldUpdateSubscription,
      setShouldRequest: setShouldUpdateSubscription,
    },
    settingName: 'update_subscription',
  })

  const reactivateSubscriptionUrl = `${endpoint('subscriptioncommand')}/subscriptions/${userId}${
    routes.subscriptionCommand.activate
  }`

  const activateSubscription = useCallback(() => {
    if (isActivatingSubscription && (updateResponse as any)?.status === 'ok') {
      fetch(
        accessToken,
        reactivateSubscriptionUrl,
        { deliveryDate: subscriptionDeliveryDate },
        'POST',
      )
        .then((response: SubscriptionResponse) => {
          dispatch({
            type: actionTypes.SUBSCRIPTION_STATUS_UPDATE_RECEIVED,
            data: response.data,
          })

          trackSubscriptionSettingsChange({
            settingName: 'reactivate_subscription',
            action: 'complete',
          })({
            delivery_date: subscriptionDeliveryDate,
            frequency: currentDeliveryFrequency,
            box_size: chosenBoxSize,
          })

          onSubmitCompleted()
        })
        .catch((error: SubscriptionErrorResponse) => {
          if (error?.errors && error?.status === 400) {
            const hasToChooseAnotherDayErrorMsg = error.errors.some((msg) =>
              msg.includes('Please choose another day'),
            )

            if (hasToChooseAnotherDayErrorMsg) {
              setActivateSubsErrorMessage(
                "Delivery cut-off time has passed which means your slot isn't available anymore. Please select another day",
              )
            }
          }

          setIsErrorPanelVisible(true)
        })
        .finally(() => {
          setIsActivatingSubscription(false)
        })
    }
  }, [
    isActivatingSubscription,
    updateResponse,
    accessToken,
    reactivateSubscriptionUrl,
    subscriptionDeliveryDate,
    dispatch,
    onSubmitCompleted,
    chosenBoxSize,
    currentDeliveryFrequency,
  ])

  useEffect(() => {
    if (!updateLoading) {
      setIsActivatingSubscription(false)
    }
  }, [updateLoading])

  useEffect(() => {
    // Update subscription was successful, attempt to reactivate for given delivery date
    activateSubscription()
  }, [activateSubscription])

  const onConfirmClick = () => {
    setShouldUpdateSubscription(true)
    setIsActivatingSubscription(true)
  }

  const shouldRenderErrorPanel = updateError || isErrorPanelVisible

  return (
    <>
      <Box display={Display.Flex} justifyContent={JustifyContent.Center} paddingV={6}>
        <Box
          display={Display.Flex}
          flexDirection={FlexDirection.Column}
          width={['100%', '60%']}
          bg={Color.White}
          paddingH={[6]}
          paddingV={[6]}
        >
          <Join with={<Space size={5} />}>
            <Heading1 color={Color.ColdGrey_900} size={[9]}>
              Confirm subscription details
            </Heading1>
            <InformationComponent
              title="Your subscription settings"
              body="Tell us how you'd like your ongoing deliveries to be scheduled"
            />
            {shouldRenderErrorPanel && (
              <Box
                display={Display.Block}
                bg={Color.Error_50}
                borderColor={Color.Error_900}
                width="100%"
                paddingV={3}
                paddingH={3}
                borderRadius={1.5}
                data-testid="error-panel"
              >
                <Body2>
                  <Bold>Oops! Something went wrong...</Bold>
                </Body2>
                <Body2>{activateSubsErrorMessage}</Body2>
              </Box>
            )}
          </Join>
          <Space size={4} />
          <Join with={<Space size={4} />}>
            <Box>
              <Join with={<Space size={4} />}>
                <NextDeliverySection subscriptionToDays={subscriptionToDays} />
                <BoxSizeSection />
                <FrequencySection />
              </Join>
            </Box>
            <Box gap={2} display={Display.Flex}>
              <Button
                style={{
                  flex: 'auto',
                }}
                colorVariant={ButtonColorVariant.Secondary}
                data-testid="backButton"
                onClick={onBackClick}
              >
                Back
              </Button>
              <Button
                style={{
                  flex: 'auto',
                }}
                disabled={isActivatingSubscription}
                colorVariant={ButtonColorVariant.Primary}
                data-testid="confirmButton"
                onClick={onConfirmClick}
              >
                Confirm
              </Button>
            </Box>
          </Join>
        </Box>
      </Box>
    </>
  )
}
