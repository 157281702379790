import useSWR from 'swr'
import { fetchUserOrders, Order } from '@library/api-user'
import { ApiKeys } from '../configs'
import { orderModel } from '../models/orderModel'
import { datadogLogs } from '@datadog/browser-logs'

type UseOrderResult = {
  pendingOrders: Order[]
  lastDeliveredOrder: Order | undefined
  dispatchedOrder: Order | undefined
  error: Error | undefined
  isLoading: boolean
}

const model = orderModel()

const initialState = {
  pendingOrders: [],
  lastDeliveredOrder: undefined,
  dispatchedOrder: undefined,
  error: undefined,
  isLoading: true,
}

const useOrder = (): UseOrderResult => {
  const { data, error } = useSWR(ApiKeys.UserCurrentOrders, fetchUserOrders)

  if (!data) {
    return initialState
  }

  if (error) {
    datadogLogs.logger.error(`Error fetching pending orders ${JSON.stringify(error)}`)

    return {
      ...initialState,
      isLoading: false,
    }
  }

  const lastDeliveredOrder = getLastDeliveredOrder(data)
  const pendingOrders = getPendingOrders(data)
  const dispatchedOrder = getDispatchedOrder(data)

  return {
    pendingOrders,
    lastDeliveredOrder,
    dispatchedOrder,
    error,
    isLoading: false,
  }
}

const getLastDeliveredOrder = (orders: Order[]) => {
  const deliveredOrders = orders.filter((order: Order) => model.isOrderDelivered(order))

  return sortOrdersByDeliveryDateDesc(deliveredOrders)[0]
}

const sortOrdersByDeliveryDateDesc = (orders: Order[]) => {
  return orders.sort(
    (a, b) =>
      new Date(model.getDeliveryDate(b) ?? 0).getTime() -
      new Date(model.getDeliveryDate(a) ?? 0).getTime(),
  )
}

const getDispatchedOrder = (orders: Order[]) => {
  return orders.find(
    (order: Order) => model.isOrderCommitted(order) && !model.isOrderDelivered(order),
  )
}

const getPendingOrders = (orders: Order[]) => {
  const restorableOrders = orders.filter((order: Order) => model.isOrderRestorable(order))
  const pendingOrders = orders.filter((order: Order) => model.isOrderPending(order))

  return sortOrdersByDeliveryDateAsc(pendingOrders.concat(restorableOrders))
}

const sortOrdersByDeliveryDateAsc = (orders: Order[]) => {
  return orders.sort(
    (a, b) =>
      new Date(model.getDeliveryDate(a) ?? 0).getTime() -
      new Date(model.getDeliveryDate(b) ?? 0).getTime(),
  )
}

export { useOrder }
