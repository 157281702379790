import { Action } from 'redux'

export enum CardProvider {
  BENTO = 'bento',
  CHECKOUT = 'checkout',
}

export type AuthPaymentReqData = {
  order_id: number
  gousto_ref: string
  card_token: string
  amount: number
  '3ds'?: boolean
  success_url: string
  failure_url: string
  decoupled: boolean
}

export type SignupPaymentReqData = Pick<
  AuthPaymentReqData,
  'order_id' | 'gousto_ref' | 'card_token' | '3ds'
>

export type SignupPaymentProvider = 'paypal' | 'applepay' | 'checkout' | 'bento' | null

export type PaymentAction = Action<string> &
  Partial<{
    challengeUrl: string
    paymentMethod: string
    provider: CardProvider.BENTO | CardProvider.CHECKOUT
    isApplePayBraintree: boolean
    token: string
    nonce: string
    deviceData: string
    isCardTokenNotCompliantFor3ds: boolean
    isGooglePay3dsTransactionComplete: boolean
    isBillingAddressDifferent: boolean
    postcode: string
    houseNo: string
    street: string
    town: string
    county: string
    udprn: string
  }>
